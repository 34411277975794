import React, { Component, createRef } from 'react'
import Axios from 'axios';
import { Input, Modal, Select, Button, Radio, Form, Checkbox, DatePickerProps, DatePicker, Tooltip, AutoComplete, notification } from 'antd';
import $ from 'jquery';
import { Link } from "react-router-dom";
import AsideLeft from '../../components/aside/asideleft';
import AncCardView from './anccardview';
import GPALE from "../../components/gynaecology/GPALE";
import ReactToPrint from "react-to-print";
import { MENSTRUAL_HISTORY_LIST, CONCEPTION_OPTIONS, PREGNANCY_OPTIONS, DELIVERY_OPTIONS, OUTCOME_OPTIONS, PLACE_OF_DELIVERY_OPTIONS, OBSTETRIC_HISTORY_LIST, GPALE_CURRENT_PREGNANCY_DATA, LIST_PHYSICAL_EXAMINATION, LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS, TOGGLE_PRACTICE, RISK_FACTORS_LIST, OBJECTIVE_GETALL, DURATION_TYPE, INFORMED_BY, MEDICATION_ON, FETAL_MOVEMENT, PRESENTATION, RELATIONSHIP_TO_PP_BRIM, LAB_TEST_SEARCH, VaccinationList, SAVE_ANC_CARD, PREVIOUS_ANC_CARD_DETAILS, PREVIOUS_ANC_CARD_DETAILS_GET, LOADER, HANDLEVALIDANTMESSAGESPATIENTAGE } from "../../utils/constant";
import { lmpDataService, practiceService, gpaleDataService, lactatingService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import LS_SERVICE from "../../utils/localStorage";
import moment from "moment";
import BasicComponent from './basic';
import { Container } from 'react-bootstrap';
const { Option } = Select;
export default class PrintPreviewanc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            poupulated_Field: props.poupulatedFieldallow || false,
            previewparams: props.previewparams || [],
            patientInfo: props.patientInfo || [],
            risk_factors: props.risk_factors || [],
            risk_factors_options: props.risk_factors_options || [],
            allergies: props.allergies || [],
            conditions: props.conditions || [],
            menstrualHistoryListStatus: props.menstrualHistoryListStatus || false,
            menstrualHistoryList: props.menstrualHistoryList || [],
            patientDetails: props.patientDetails || [],
            current_pregnancy_data: props.current_pregnancy_data || [],
            physicalExaminationData: props.physicalExaminationData || [],
            obsHistoryListStatus: props.obsHistoryListStatus || false,
            gpale_values: props.gpale_values || [],
            obsHistoryList: props.obsHistoryList || [],
            phyExamListStatus: props.phyExamListStatus || false,
            phyExamFPListStatus: props.phyExamFPListStatus || [],
            physicalExaminationFPData: props.physicalExaminationFPData || [],
            Immunoprophylaxis_dates: props.Immunoprophylaxis_dates || [],
            testData: props.testData || [],
            duplicate_testdate: props.duplicate_testdate || [],
            test_previous: props.test_previous || [],
            test_populated: props.test_populated || [],
            vaccine_previous: props.vaccine_previous || [],
            vaccine_populated: props.vaccine_populated || [],
        }
    }
    getPatientAge(dob) {
        var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
        var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
        var ageY = ageM / 12;
        var remainM = ageM % 12;
        ageY = Math.floor(ageY);
        var ageText = '';
        if (ageM == 0)
            ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
        else if (ageY < 1)
            ageText = ageM + ' Mon' + (ageM == 1 ? '' : 's');
        else if (ageY <= 5) {
            if (remainM != 0) {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's ') + remainM + ' Mon' + (remainM == 1 ? '' : 's');
            } else {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's');
            }
        }
        else
            ageText = ageY + ' Yrs';
        return ageText;
    }
    showPhysicalExamData() {
        const __physicalExamData = this.state.physicalExaminationData;
        if (__physicalExamData?.length > 0) {
            return __physicalExamData.map((data, i) => (
                <tr key={data.id}>
                    <td style={(i + 1) == __physicalExamData.length ? { paddingBottom: '20px' } : {}}>
                        {moment(data.createdAt).format("D MMM, YYYY")} | {moment(data.createdAt).format("h:mm A")}
                    </td>
                    <td>{data.pogWeeks || '-'}</td>
                    <td>{data.weight || '-'}</td>
                    <td>{data.bpmm || '-'}</td>
                    <td>{data.pulseRate || '-'}</td>
                    <td>{data.fundalHeight || '-'}</td>
                    <td>{data.edema || '-'}</td>
                    <td>{data.pallor || '-'}</td>
                    <td>{data.cervixExam || '-'}</td>
                    <td>{data.pretermLabour || '-'}</td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
    }
    showPhysicalExamFPData() {
        const __physicalExamFPData = this.state.physicalExaminationFPData;
        if (this.props.total_count_fetal == 0) {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
        if (__physicalExamFPData && __physicalExamFPData.length > 0) {
            return __physicalExamFPData.map((data, i) => (
                data.fetal_params.map((d, j) => (
                    <tr key={d.id} className={j != 0 ? 'highlight_bg' : ''}>
                        {j == 0 ?
                            <td rowSpan={data.fetal_params.length}> {moment(data.createdAt).format("D MMM, YYYY")} | {moment(data.createdAt).format("h:mm A")}
                            </td>
                            : null}
                        <td className="text-center" style={(i + 1) == __physicalExamFPData.length && (j + 1) == data.fetal_params.length ? { paddingBottom: '20px' } : {}}>{j + 1}</td>
                        <td className="text-center">{this?.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                        <td className="text-center">{d.fetalHeartRate || '-'}</td>
                        <td className="text-center">{this?.getListValue(d.presentation, PRESENTATION)}</td>
                        <td className="text-center">{this?.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                        <td className="text-center">{d.headCc || '-'}</td>
                        <td className="text-center">{d.abdominalCc || '-'}</td>
                        <td className="text-center">{d.femurLength || '-'}</td>
                        <td style={{ wordBreak: "break-all" }} className="text-center">{data.remarks || '-'}</td>
                    </tr>
                ))
            ));
        } else {
            return (
                <tr>
                    <td colSpan="11">No data available</td>
                </tr>
            );
        }
    }
    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }

    render() {
        const { poupulated_Field, patientInfo, patientDetails } = this.state
        let previewparams = this.props.previewparams
        return (
            <>

                <div className="container-fluid emrfrm anc-container mb-4 ">
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <div className="float-left">
                                <h1 className="page-title mb-0">ANC Card</h1>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card anc-cardgrey'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='heading'>Patient Name :&nbsp;</span>
                                        {(patientInfo) ?
                                            <span className='patient_name'>{patientInfo?.first_name !== undefined ? patientInfo?.first_name : null}&nbsp;{patientInfo?.middle_name !== undefined ? patientInfo?.middle_name : null}&nbsp;{patientInfo?.last_name !== undefined ? patientInfo?.last_name : null}, {patientInfo?.dob && patientInfo?.age !== null ? this.getPatientAge(patientInfo?.dob) : null}, {patientInfo?.gender !== undefined && patientInfo?.gender !== null ? (patientInfo?.gender == 'Male' ? ' (M)' : ' (F)') : null}</span>
                                            : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='heading'>Address :&nbsp;</span>
                                        {(patientInfo) ?
                                            <span className='patient_name'>{patientInfo?.address_1 !== undefined ? patientInfo?.address_1 : null}, {patientInfo?.city !== undefined ? patientInfo?.city : null},   {patientInfo?.state !== undefined ? patientInfo?.state : null}</span>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row mt-4'>
                        {(this.props?.poupulatedFieldallow == true && this.state?.risk_factors?.length <= 0) ?
                            null
                            :
                            <div className='col-6' >
                                <span style={{ color: "#FF0000" }}>Risk Factors</span>
                            </div>
                        }

                        {(this.props?.poupulatedFieldallow == true && previewparams?.risk_factors_management == null) ?
                            null
                            :
                            <div className='col-6'>
                                <span style={{ color: "#FF0000" }}>Risk Factors Management</span>
                            </div>
                        }
                    </div>

                    <div className='row '>
                        {(this.props?.poupulatedFieldallow == true && this.state.risk_factors.length == 0) ?
                            null
                            :
                            <div className='col-6'>
                                <table>
                                    {
                                        this.state.risk_factors_options?.map(obj => {
                                            return this.state.risk_factors?.map(
                                                item => {
                                                    if (obj.id == item) {
                                                        return (
                                                            <tr>{obj.description}</tr>
                                                        )
                                                    }
                                                }
                                            )
                                        })

                                    }
                                </table>
                            </div>
                        }
                        <div className='col-6'>
                            <span><pre style={{ fontSize: "15px", border: "none" }}>{previewparams?.risk_factors_management}</pre></span>
                        </div>
                    </div>
                    {this.props?.poupulatedFieldallow != true || (this.state?.allergies.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'> Allergies</span>
                                </div>
                            </div>

                            <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="28%">ALLERGY NAME</td>
                                        <td width="22%">REACTION</td>
                                        <td width="15%">DURATION</td>
                                        <td width="15%">INTENSITY</td>
                                        <td width="15%">INFORMED BY</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.allergies.length > 0 ?
                                        this.state.allergies.map((obj) => (
                                            <tr>
                                                <td>{obj.allergy_name}</td>
                                                <td>{obj.reaction ? obj.reaction : '-'}</td>
                                                <td>{obj.duration ? obj.duration + ' ' + DURATION_TYPE[obj.durationType] : '-'}</td>
                                                <td>{obj.intensity === 0 ? "Low" : obj.intensity === 1 ? "Medium" : obj.intensity === 2 ? "High" : ''}</td>
                                                <td>{obj.informedBy ? INFORMED_BY[obj.informedBy] : '-'}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </>
                        : null}

                    {this.props?.poupulatedFieldallow != true || (this.state.conditions.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'>Existing Condition</span>
                                </div>
                            </div>

                            <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="30%">CONDITIONS</td>
                                        <td width="20%">DURATION</td>
                                        <td width="20%">ON MEDICATION</td>
                                        <td width="30%">MEDICINE NAME</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.conditions.length > 0 ?
                                            this.state.conditions.map((c, i) => (
                                                <tr>
                                                    <td>{c.condition_description}</td>
                                                    <td>{(c.duration) ? `${c.duration} ${DURATION_TYPE[c.durationType]}` : '-'}</td>
                                                    <td>{MEDICATION_ON[c.onMedication] ? MEDICATION_ON[c.onMedication] : '-'}</td>
                                                    <td>{c.medicineName || "NA"}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="6">No data available</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </>
                        : null}

                    {this.props?.poupulatedFieldallow != true || (this.state.menstrualHistoryList.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-3'>
                                    <span className='title'>Menstrual Hx</span>
                                </div>
                                {(patientDetails && patientDetails.lmp_date) ?
                                    <div className='col-2'>
                                        <span>LMP:</span>  <span className='title'>{patientDetails.lmp_date}</span>
                                    </div>
                                    : null}
                                <div className='col-2'>
                                    {this.state?.menstrualHistoryList.length > 0 ?
                                        <>
                                            <span>Cycle Length:</span>  <span className='title'>{this.state?.menstrualHistoryList[0]?.cycle_length+ " days"}</span>
                                        </>
                                        : null}
                                </div>
                                {this.state?.menstrualHistoryList[0]?.created_at ?
                                    <div className='col-5 text-right'>
                                        <span className='title'>Last Updated
                                            ({moment(this.state?.menstrualHistoryList[0]?.created_at).format("DD MMM, YYYY HH:mm")} | {
                                                this.state?.menstrualHistoryList[0]?.created_at_time}
                                            )</span>
                                    </div>
                                    : null}
                            </div>

                            {/* <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="10%">DATE</td>
                                        <td width="20%">CYCLE LENGTH</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.menstrualHistoryList !== undefined && this.state.menstrualHistoryList && this.state.menstrualHistoryList.length > 0 ?
                                        this.state.menstrualHistoryList.map((m, i) => (
                                            <tr>
                                                <td>{m.created_at + " | " + m.created_at_time}</td>
                                                <td>{(m.cycle_length ? m.cycle_length : '-')}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="8">No data available</td>
                                        </tr>
                                    }
                                </tbody>
                            </table> */}
                        </>
                        : null}

                    {this.props?.poupulatedFieldallow != true || (this.state.obsHistoryList.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4 d-flex align-items-center'>
                                <div className='col-2'>
                                    <span className='title'>Obstetrics Hx</span>
                                </div>
                                {this.state.current_pregnancy_data?.eddDate ?
                                    <div className='col-3'>
                                        <span>EDD by LMP:</span><span className='title'> {this.state.current_pregnancy_data?.eddDate ? moment(this.state.current_pregnancy_data?.eddDate).format("D MMM, YYYY") : null}</span>
                                    </div>
                                    : null}
                                {this.state.current_pregnancy_data?.correctedEddDate != null && this.state.current_pregnancy_data?.correctedEddDate != "" && this.state.current_pregnancy_data?.correctedEddDate != undefined ?
                                    <div className='col-auto'>
                                        <div className='card obs_card'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {this.state.current_pregnancy_data?.correctedEddDate ? <>
                                                        <span className='title_red'>Corrected EDD:</span><span >{moment(this.state.current_pregnancy_data?.correctedEddDate).format("D MMM, YYYY")}</span>
                                                    </>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className='col-3 text-right'>
                                    {this.state?.current_pregnancy_data?.lactating ?
                                        <span className='title'>Lactating</span>
                                        : null}
                                </div>
                                <div className='col text-right'>
                                    {this.state?.gpale_values ?
                                        <span className='title'><GPALE values={this.state?.gpale_values} /></span>
                                        : null}
                                </div>
                            </div>
                            {this.state.current_pregnancy_data?.remarksForCurrentPregnancy && this.state.current_pregnancy_data?.remarksForCurrentPregnancy != "" && this.state.current_pregnancy_data?.remarksForCurrentPregnancy != null ?
                                <div className='row mt-4'>
                                    <div className='col-2'>
                                        <span className='title'>Obstetrics Remarks</span>
                                    </div>
                                    <div className='col-10'>
                                        <pre style={{ fontSize: "15px", border: "none" }}>{this.state.current_pregnancy_data.remarksForCurrentPregnancy}</pre>
                                    </div>
                                </div>
                                : null}
                            {/* <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="20%">YEAR OF OUTCOME</td>
                                        <td width="20%">MODE OF CONCEPTION</td>
                                        <td width="15%">PREGNANCY TYPE</td>
                                        <td width="15%">DELIVERY TYPE</td>
                                        <td width="30%">OUTCOME</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.obsHistoryList !== undefined && this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                                        this.state.obsHistoryList.map((data, i) => (
                                            <tr>
                                                <td>{data.pregnancy_year}</td>
                                                <td>{data.mode_text}</td>
                                                <td>{data.pregnancy_type_text}</td>
                                                <td>{data.delivery_type_text}</td>
                                                <td>{data.outcome_text}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    }
                                </tbody>
                            </table> */}
                        </>
                        : null}

                    {this.props?.poupulatedFieldallow != true || ((this.state.physicalExaminationData.length > 0 || this.state.phyExamFPListStatus.length > 0) && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'> Physical Examination</span>
                                </div>
                            </div>

                            {this.state.phyExamListStatus || this.state.phyExamFPListStatus || this.state.physicalExaminationData ?
                                <>
                                    <div className='row mt-3 '>
                                        <div className='col-4'>
                                            <span className='title'>Mother's Parameters</span>
                                        </div>
                                        <div className='col-8 text-right'>
                                            {this.state?.physicalExaminationData?.length > 0 ?
                                                <span className='title'>Last Updated({this.state?.physicalExaminationData?.map((data, index) => (index == 0 ? moment(data.createdAt).format("DD MMM, YYYY | h:mm A") : ''))})</span>
                                                : null}
                                        </div>
                                    </div>
                                    <table className="table-bordered mt-3" >
                                        <thead>
                                            <tr>
                                                <td width="15%">PARAMETERS</td>
                                                <td width="8%">POG (WEEKS)</td>
                                                <td width="10%">WEIGHT (KG)</td>
                                                <td width="5%">BP (MMHG)</td>
                                                <td width="10%">PULSE RATE (MIN)</td>
                                                <td width="10%">FUNDAL HT (CM)</td>
                                                <td width="10%">EDEMA</td>
                                                <td width="10%">PALLOR</td>
                                                <td width="12%">CERVIX EXAM</td>
                                                <td width="15%">PRETERM LABOUR (SIGN/SYMPTOMS)</td>
                                            </tr>
                                        </thead>
                                        {this.showPhysicalExamData()}
                                    </table>
                                </>
                                : null}

                        </>
                        : null}

                    {this.props?.poupulatedFieldallow != true || (this.props?.total_count_fetal !== 0 && this.props?.poupulatedFieldallow == true) ?
                        <>
                            <div className='row mt-4 '>
                                <div className='col-4'>
                                    <span className='title'>Fetal Parameters</span>
                                </div>
                                <div className='col-8 text-right'>
                                    {this.state?.physicalExaminationFPData?.length > 0 ?
                                        <span className='title'>Last Updated({this.state?.physicalExaminationFPData?.map((data, index) => (index == 0 ? moment(data.updatedAt).format("DD MMM, YYYY | h:mm A") : ''))})</span>
                                        : null}
                                </div>
                            </div>
                            <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="10%">PARAMETERS</td>
                                        <td width="10%">FETUS NO.</td>
                                        <td width="10%">FETAL MOVEMENT</td>
                                        <td width="10%">FHR(PER MINUTE)</td>
                                        <td width="10%">PRESENTATION</td>
                                        <td width="10%">RELATION OF PP TO BRIM</td>
                                        <td width="10%">HEAD CC (CM)</td>
                                        <td width="10%">ABDOMINAL CC (CM)</td>
                                        <td width="10%">FEMUR LENGTH(CM)</td>
                                        <td width="10%">REMARKS</td>
                                    </tr>
                                </thead>
                                {this.showPhysicalExamFPData()}
                            </table>
                        </>
                        : null}


                    {this.state.vaccine_populated.length > 0 && previewparams?.immuno_remarks != null ?
                        <div className='row mt-4 '>
                            <div className='col-12'>
                                <span className='title'>Immunoprophylaxis</span>
                            </div>
                        </div>
                        : null}

                    <div className='row mt-3'>
                        {this.props?.poupulatedFieldallow == true
                            ?
                            this.state.vaccine_populated.map(obj => {
                                return (
                                    <div className='col-3'>
                                        <span>{obj.name}</span>
                                        <b>: {obj.date}</b>
                                    </div>
                                )
                            }) :
                            (this.state?.vaccine_previous != undefined && this.state?.vaccine_previous.length > 0) ?
                                this.state.vaccine_previous.map(obj => {
                                    return (
                                        <div className='col-3'>
                                            <span>{obj.name}</span>
                                            <b>: {obj.date ? obj.date : "  -"}</b>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>

                    {(this.props?.poupulatedFieldallow == true && previewparams?.immuno_remarks == null) ?
                        null
                        :
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label htmlFor="immuno_remarks">Immunoprophylaxis Remarks: </label>
                                <b> <pre style={{ fontSize: "15px", border: "none" }}>{previewparams?.immuno_remarks}</pre></b>
                            </div>
                        </div>
                    }

                    {this.props?.poupulatedFieldallow != true || (this.state?.test_populated?.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>

                            <div className='row mt-4 '>
                                <div className='col-12'>
                                    <span className='title'>Lab Tests</span>
                                </div>
                            </div>

                            <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="17%">DATE</td>
                                        <td width="20%">TEST NAME</td>
                                        <td width="46%">RESULT/IMPRESSION</td>
                                        <td width="17%">REFERENCE RANGE</td>
                                    </tr>
                                </thead>
                                {this.props?.test_populated?.length > 0 ?
                                    <tbody>
                                        {
                                            this.props?.poupulatedFieldallow == true ?
                                                this.props?.test_populated.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                            <td> <span> {obj.name ? obj.name : "-"}</span>  </td>
                                                            <td> <span> {obj.result ? obj.result : "-"}</span>  </td>
                                                            <td>  <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                this.state?.test_previous.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                            <td> <span> {obj.name ? obj.name : "-"}</span> </td>
                                                            <td> <span> {obj.result ? obj.result : "-"}</span> </td>
                                                            <td> <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </tbody>
                                    : <tbody>
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>

                        </>
                        : null
                    }

                    {this.props?.poupulatedFieldallow != true || (this.props?.radio_test_populated?.length > 0 && this.props?.poupulatedFieldallow == true) ?
                        <>

                            <div className='row mt-4 '>
                                <div className='col-12'>
                                    <span className='title'>Radiology Tests</span>
                                </div>
                            </div>

                            <table className="table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="17%">DATE</td>
                                        <td width="20%">TEST NAME</td>
                                        <td width="46%">RESULT/IMPRESSION</td>
                                        <td width="17%">REFERENCE RANGE</td>
                                    </tr>
                                </thead>
                                {this.props?.radio_test_populated?.length > 0 ?
                                    <tbody>
                                        {
                                            this.props?.poupulatedFieldallow == true ?
                                                this.props?.radio_test_populated?.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                            <td> <span> {obj.name ? obj.name : "-"}</span>  </td>
                                                            <td> <span> {obj.result ? obj.result : "-"}</span>  </td>
                                                            <td>  <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                this.state?.radio_test_previous?.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                            <td> <span> {obj.name ? obj.name : "-"}</span> </td>
                                                            <td> <span> {obj.result ? obj.result : "-"}</span> </td>
                                                            <td> <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </tbody>
                                    : <tbody>
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>

                        </>
                        : null
                    }

                    {(this.props?.poupulatedFieldallow == true && previewparams?.delivery_dateChange == null && previewparams?.delivery_place == null && previewparams?.delivery_mode == null && previewparams?.indication_csection == null && previewparams?.details == null && previewparams?.delivery_outcome == null) ?
                        null
                        :
                        <div className='row mt-4 '>
                            <div className='col-12'>
                                <span className='title'>Delivery Details</span>
                            </div>
                        </div>
                    }
                    <div className='row mt-3'>
                        {/* {(this.props?.poupulatedFieldallow == true && previewparams?.delivery_dateChange == null) ?
                            null
                            :
                            <div className='col-4'>
                                <span>Date & Time:</span>
                                <b> {previewparams?.delivery_dateChange ? moment(previewparams.delivery_dateChange).format("DD MMM, YYYY HH:mm") : null}</b>
                            </div>
                        } */}

                        {(this.props?.poupulatedFieldallow == true && previewparams?.delivery_mode == null) ?
                            null
                            :
                            <div className='col-4'>
                                <span>Mode:</span>
                                <b> {previewparams?.delivery_mode === "1" ? "Natural" :
                                    previewparams?.delivery_mode === "2" ? "IVF" :
                                        previewparams?.delivery_mode === "3" ? "IUI" : ''}</b>
                            </div>
                        }

                        {(this.props?.poupulatedFieldallow == true && previewparams?.delivery_mode == null) ?
                            null
                            :
                            <div className='col-3'>
                                <span>Pregnancy Type:</span>
                                <b> {previewparams?.pregnancy_type == "1" ? "Single" :
                                    previewparams?.pregnancy_type == "2" ? "Multiple" :
                                        previewparams?.pregnancy_type == "3" ? "Ectopic" : '-'}</b>
                            </div>
                        }
                    </div>

                    {this.props.previewparams?.outcome_group?.length > 0 ?
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <span>Outcome:</span>
                                {this.props.previewparams?.outcome_group?.map(obj => {
                                    return (
                                        <>
                                              {/* <div className='row mt-2  ml-2'>
                                                <b> <span> {obj.outcome == "1" ? "Live Term" :
                                                    obj.outcome == "2" ? "Live Pre-Term" :
                                                        obj.outcome == "3" ? "Still born Term" :
                                                            obj.outcome == "4" ? "Still born Pre-Term" :
                                                                obj.outcome == "5" ? "Spontaneous Abortion" :
                                                                    obj.outcome == "6" ? "MTP" : '-'} ,</span> </b> &nbsp;
                                                {obj?.delivery_type ? <> <b><span>{obj.delivery_type == "1" ? "Normal" :
                                                    obj.delivery_type == "2" ? "LSCS" :
                                                        obj.delivery_type == "3" ? "Forceps" :
                                                            obj.delivery_type == "4" ? "Water Birthing" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                {obj?.birth_date_time ? <b><span>Birth Date & Time:- </span> {moment(obj?.birth_date_time).format("DD MMM, YYYY hh:mm A")} , </b> : null}
                                                {obj?.gender ? <> <b> <span> {obj.gender == "1" ? "Male" :
                                                    obj.gender == "2" ? "Female" :
                                                        obj.gender == "3" ? "Transgender" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                {obj?.birth_weight ? <> <span>Birth wt<b> {obj.birth_weight ? obj.birth_weight : "-"}</b> kg</span> , &nbsp;  </> : null}
                                                {obj?.place_of_delivery ? <> <b><span>{obj.place_of_delivery === "1" ? "Hospital" :
                                                    obj.place_of_delivery === "2" ? "Home" :
                                                        obj.place_of_delivery === "3" ? "PHC" :
                                                            obj.place_of_delivery === "4" ? "Others" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                <b><span>Congenital Abnormality:- </span> {obj?.abnormality == true ? "Yes" : "No"} , </b> &nbsp;
                                                {obj?.abnormality == true ?
                                                    <b><span>Congenital Abnormality Remark:- </span> {obj?.abnormality_remark} </b>
                                                    : null}
                                            </div> */}

                                            <div className='row mt-2 ml-2'>
                                                <span><b>Outcome:&nbsp;</b>{obj.outcome == "1" ? "Live Term" :
                                                    obj.outcome == "2" ? "Live Pre-Term" :
                                                        obj.outcome == "3" ? "Still born Term" :
                                                            obj.outcome == "4" ? "Still born Pre-Term" :
                                                                obj.outcome == "5" ? "Spontaneous Abortion" :
                                                                    obj.outcome == "6" ? "MTP" : '-'}, &nbsp;</span>

                                                {obj?.delivery_type ?
                                                    <>
                                                        <span><b>Mode of Delivery:</b>&nbsp; {obj.delivery_type == "1" ? "Normal" :
                                                            obj.delivery_type == "2" ? "LSCS" :
                                                                obj.delivery_type == "3" ? "Forceps" :
                                                                    obj.delivery_type == "4" ? "Water Birthing" : '-'},&nbsp;
                                                        </span>
                                                    </>
                                                    : null}
                                                {obj?.place_of_delivery ? <><span>and <b>Place of delivery:&nbsp;</b>{obj.place_of_delivery === "1" ? "Hospital" :
                                                    obj.place_of_delivery === "2" ? "Home" :
                                                        obj.place_of_delivery === "3" ? "PHC" :
                                                            obj.place_of_delivery === "4" ? "Others" : '-'}</span>&nbsp;  </> : null}
                                            </div>

                                            <div className='row mt-1 ml-2'>
                                                {obj?.birth_date_time ? <span><b>Birth Date & Time:</b>  {moment(obj?.birth_date_time).format("DD MMM, YYYY | hh:mm A")},&nbsp; </span> : null}

                                                {obj?.gender ? <span><b>Gender:</b>  {obj.gender == "1" ? "Male" :
                                                    obj.gender == "2" ? "Female" :
                                                        obj.gender == "3" ? "Transgender" : '-'} ,&nbsp; </span> : null}
                                                {obj?.birth_weight ? <span><b>Birth wt:</b>  {obj?.birth_weight ? obj?.birth_weight + " kg" : '-'}</span> : null}
                                            </div>
                                            {
                                                obj?.abnormality ?

                                                    <div className='row mt-1 ml-2'>
                                                        <span><b>Congenital Abnormality:&nbsp;</b>{obj?.abnormality == true ? "Yes" : "No"}</span>
                                                        {obj?.abnormality == true ?
                                                            <span>,&nbsp; <b>Congenital Abnormality Remark:</b>&nbsp;{obj?.abnormality_remark} </span>
                                                            : null}
                                                    </div> : null
                                            }
                                          
                                        </>
                                    )
                                })
                                }
                            </div>
                        </div>
                        : null}


                    <div className='row mt-3'>
                        {(this.props?.poupulatedFieldallow == true && previewparams?.indication_csection == null) ?
                            null
                            :
                            <div className='col-6'>
                                <span>Indication for C-Section:</span>
                                <b> {previewparams?.indication_csection}</b>
                            </div>
                        }

                        {(this.props?.poupulatedFieldallow == true && previewparams?.details == null) ?
                            null
                            :
                            <div className='col-6'>
                                <span>Details:</span>
                                <b> {previewparams?.details}</b>
                            </div>
                        }
                    </div>
                </div>

            </>
        );
    }

}
