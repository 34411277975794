import axios from 'axios';
import qs from 'qs';
import React, { Component } from 'react';
import Bowser from "bowser";
import moment from "moment";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import debounce from 'lodash/debounce';
import {
  Link
} from 'react-router-dom';
import {
  Radio,
  Select,
  Modal,
  Tooltip,
  Carousel,
  Image,
  notification
} from 'antd';

import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import SoapFlow from '../../utils/soap';
import VitalsKeyFindings from './sscomponents/vitals-keyfindings';
import ChiefComplaintsHPI from './sscomponents/chiefcom-hpi';
import ClinicalNotes from './sscomponents/clinical-notes';
import InternalNotes from './sscomponents/internal-notes';
import ObjectiveComponentSS from './objectivess';
import AssessmentComponentSS from './sscomponents/assessmentss';
import ReferralComponentSS from './referralss';
import PlanTestComponentSS from './planTestSS';
import PlanMedicineComponentSS from './planMedicineSS';
import SupportingMaterialSS from '../../components/supporting-material/supporting-material-form-ss';
import InstructionsForm from '../../components/instructions/instructions-form';
import CurrentMedsPopup from '../../components/drugs/current-meds-popup';
import AdmitOrders from '../../components/admit-orders/admit-orders-form';
import SurgeryForm from '../../components/new-surgery/new-surgery-form';
import TextSaveImg from "../../assets/images/textsave.png";
import NotesSaveModel from "../notes-model/NotesSaveModel";


import {
  AntSelectLabels
} from '../../utils/jQueryLabels';
import {
  headerService$,
  practiceService,
  speechService,
  speechTextService,
  doctorNotesService
} from '../../utils/rxjs-sharing';
import {
  ISALLOWEDITSOAP,
  DRUGS_CONSTANT,
  LAB_TEST_CONSTANTS,
  LAB_TEST_SEARCH,
  DEFAULTVITALGROUPID,
  HOSPITALDEFAULTS,
  SEARCH_DIAGNOSIS,
  FETCH_SUPPORTING_MATERIAL,
  PATIENT_DATA,
  HOMECARE_REFERENCE_TYPE,
  INSTRUCTION_STORE,
  DURATION_TYPE_OBJECT,
  INTUITIVE_CONSTANTS,
  DURATION_TYPE_OBJECT_REVERSE,
  PHYSIO_UPADTE,
  ADMIS_UPADTE,
  ADMISSION_UPDATE,
  TEMPLATE_FILTER,
  SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS,
  SUBJECTIVE_INFRMATION_CUSTOM,
  SUBJECTIVE_INFRMATION_INTERNAL_NOTES,
  SUBJECTIVE_INFRMATION_CLINICAL_NOTES,
  INTUITIVE_DIAGNOSIS_AND_CP,
  MYHC_APPTYPE,
  WOODLANDS,
  FOLLOWUP_UPDATE,
  TOGGLE_PRACTICE,
  REFERRAL_DETAILS,
  INSTRUCTION_SEARCH,
  SOAP_OBJECTIVE_DETAILS,
  LOADER_RED,
  TAB_NAMES,
  SUBJECTIVE_CUSTOM_STORE,
  PARENT_CUSTOM_STORE,
  LOADER,
  UPDATE_QMS_STATUS,
  COMPLETE_APPOINTEMENT,
  SEPARATEUPLOADEDDOCSNEW,
  SUBJECTIVE_INFRMATION,
  GET_ARRAY_BUFFER_FORM_URL,
  USERTYPES,
  PARENT_INFRMATION_CUSTOM,
  GET_EXISTING_SURGERIES,
  APP_ENV,
  ACTIVE_ADMISSION_REQUEST,
  PATIENT_INSTRUCTION_DELETE,
  GET_DEFAULT_TEXT,
} from '../../utils/constant';




const { Option } = Select;
class SingleScreenCommon extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      reference_id: null,
      details: [],
      field_status: [],
      dataLoading: true,
      physiotherapy_advised: 2,
      physioText: '',
      admissionChecked: false,
      admissionAdvisedDisabled : false,
      admissionComment: '',
      icu_days: '',
      ward_days: '',
      case_type: null,
      followUpRadio: null,
      follow_up_days: null,
      suggestedCP: [],
      otherCP: [],
      allCP: [],
      subjective_custom: {},
      parent_customdata: {},
      cpDiagnosisType: 'Favorites',
      suggestedDiagnosis: [],
      cc_keyword: [],
      internal_notes_keyword: [],
      clinical_notes_keyword: [],
      isAdmissionAdvisedPriority: false,
      follow_up_Others: 'Days',
      otherVal: null,
      disableCompleteButton: true,
      bookNowAllow: true,
      referralDetailsArr: [],
      isReferralModal: false,
      showSupportingModal: false,
      supportingMaterial: [],
      allSupportingMaterial: [],
      isSupportingMaterialShow: true,
      isPracticing: false,
      isPracticingModal: false,
      hide_protocol_by_medi: false,
      hide_protocol_by_test: false,
      hospital_id: null,
      disableConfirmButton: false,
      viewDocumentModal: false,
      allowEdit: true,

      favourite: {},
      intuitive: {},
      constants: {},
      materialList: [],

      active_tab: 'subjective',
      isObjectiveShow: false,
      isMedShow: false,
      isTestShow: false,
      isPrintLoader: false,
      documentURL: '',
      downloadAllfiles: false,
      download_file_loading: false,
      isReferralModal: false,
      showdeletebutton: true,
      speech: {
        identifier: null,
        state: false
      },
      reviewNotes: '',
      reviewTxt: '',
      is_recall_doctor: 0,
      deleteInstructionModal: false,
      medicineDataEmpty: false,
      admitOrderModal:false,
      surgeryRequestModal: false,
      surgeryButtonEnabled : false,
      surgeryRequired: false,
      viewAdmissionGrid : false,
      admissionListData: [],
      junior_doctor_complete_appointment: false,
      content: '',
      input_type: '',
      model_type: '',
      notesModelActive: false,
      default_text:[]
    }
    this.getMaterialData = this.getMaterialData.bind(this);
    this.deleteAllInstruction = this.deleteAllInstruction.bind(this);
    this.handleTestModalPopup = this.handleTestModalPopup.bind(this);
    this.handleDeletePopSubmit = this.handleDeletePopSubmit.bind(this);
    this.instructionCollapseRef = React.createRef();
    this.carouselRef = React.createRef();
    this.beforeChange.bind(this);
    this.medicineCollapseRef = React.createRef();
    this.testCollapseRef = React.createRef();
    this.subjectiveChange = debounce(this.subjectiveChange, 800);
    this.instructionCollapseRef = React.createRef();
    this.medicineComponentRef = React.createRef();
    this.testComponentRef = React.createRef();
    this.assessmentComponentRef = React.createRef();
    this.vitalAndKfComponentRef = React.createRef();
    this.chiefComponentRef = React.createRef();
    this.viewMedicineData = this.viewMedicineData.bind(this);
    this.addedDrugs = this.addedDrugs.bind(this);
    this.viewTestData = this.viewTestData.bind(this)
    this.printCompleteButtonDiabled = this.printCompleteButtonDiabled.bind(this);
    this.saveCustomSubjectiveFromDoctor = this.saveCustomSubjectiveFromDoctor.bind(this);
    this.saveTextBoxData = this.saveTextBoxData.bind(this);
    this.resetNotesModel = this.resetNotesModel.bind(this);
  }

  handleAdmitOrderModal = async()  => {
    this.setState({
      admitOrderModal : false,
    })
  }

  handleCaseType = async (e) => {
    const { name, value } = e.target;
    if(value == 1)
    {
      this.setState({
        surgeryButtonEnabled : true
      });
      await this.getExisitingSurgeries();
    }
    else
    {
      this.setState({
        surgeryButtonEnabled : false,
        surgeryRequired: false
      })
    }
    await this.setState({
      [name]: value
    }, this.admissionSave);
  }

  async getExisitingSurgeries() {
    const { patient } = this.state;
    const { qms_token_id } = patient;
    const surgery_list_data = await axios.get(GET_EXISTING_SURGERIES({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: qms_token_id }));
    if (typeof surgery_list_data.data != 'undefined' && surgery_list_data.data.status == false) {
      await this.setState({
        surgeryRequired : true,
      });
    }
  }

  addedDrugs = (data) => {
    this.setState({
      addedDrugsList: data
    })
  }

  handleAllInteractionCancel = () => { this.setState({ isModalAllInteractionVisible: false }); };

  handleAllInteractionOk = () => { this.setState({ isModalAllInteractionVisible: false }) };

  handleTabClick = (tab) => {
    if (tab == 'objective') {
      this.setState({
        isObjectiveShow: true
      });
    }
    else if (tab == "plan_test") {
      this.setState({
        isTestShow: true
      });
    }
    else if (tab == "plan_medicine") {
      this.setState({
        isMedShow: true
      });
    }
  }

  handleAdmitOrder = event => {
    this.setState({
      admitOrderModal: true,
    });
  }

  handleSurgeryRequest = event => {
    this.setState({
      surgeryRequestModal: true,
    });
  }

  handleSurgeryCancel = () => {
    this.setState({
      surgeryRequestModal: false,
      surgeryRequired : false
    });
  }

  handleOnTextChange = event => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value
    }, this.admissionSave);
  }

  loadCareProtocolAddedData = async () => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
      isSupportingMaterialShow: false
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
      isSupportingMaterialShow: true
    });
    this.loadInstructionData();
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.subjectiveChange();
  }

  loadCopyPrescriptionAddedData = async () => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
      isSupportingMaterialShow: false
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
      isSupportingMaterialShow: true
    });
    this.loadInstructionData();
    this.assessmentComponentRef.current.handleAssessmentData();
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.subjectiveChange();
    this.getCustomSubjectiveData();
  }

  autoHeight = (e) => {
    if (e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  }

  viewlengthMedicineData(data) {
    if (data == 0) {
      this.setState({
        hide_protocol_by_medi: false
      })
    } else {
      this.setState({
        hide_protocol_by_medi: true
      })
    }
  }

  viewlengthTestData(data) {
    if (data == 0) {
      this.setState({
        hide_protocol_by_test: false
      })
    } else {
      this.setState({
        hide_protocol_by_test: true
      })
    }
  }

  viewMedicineData() {
    if (this.medicineCollapseRef.current?.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  viewTestData() {
    if (this.testCollapseRef.current?.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }
  // componentWillMount() {
  //   this.subscribtion?.unsubscribe();
  // }

  componentDidMount() {
    // this.subscribtion = medicineDataEmptyDisableButton.status().subscribe(data => {
    //   this.setState({disableCompleteButton:!data.medicineDataEmpty})
    // })
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    this.setState({
      isMedShow: true,
      isTestShow: true,
      reference_id: this.props.reference_id,
      appointment_status: this.state.appointment_status
    })
    const hospital_id = this.props.hospital_id;
    const patient_id = this.props.patient_id;
    const reference_id = this.props.reference_id;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    const ss_configuration = LS_SERVICE.get('ss_configuration');
    this.setState({
      ss_configuration: ss_configuration,
      consult_type: 'vc'
    })
    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true,
      })
    }

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

    this.loadData();
    this.setState({
      hospital_id,
      patient_id,
      patient,
      pres_modification: hospitalConfigData?.pres_modification,
      allow_hours: hospitalConfigData?.allow_hours,
      allowEdit
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getPatientData();
        SoapFlow.hospital(+hospital_id);
        this.loadInstructionData();
      }
    });
    this.getCustomSubjectiveData();
    this.getCustomParentData();
    this.subscription = speechTextService.status().subscribe(async data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'custom_field_1') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom1: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_2') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom2: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_3') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom3: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_4') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom4: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_5') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom5: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_1') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom1: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_2') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom2: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_3') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom3: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_4') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom4: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_5') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom5: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'review_notes') {
          await this.setState({ reviewTxt: data.text });
        }
        document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
      }
    });
    this.getReferralDetails();
    let Elem = document.querySelectorAll('.autoHeightText');
    Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
  }

  async componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  deleteAllInstruction() {
    this.setState({
      deleteInstructionModal: true
    })
  }

  handleTestModalPopup() {
    this.setState({
      deleteInstructionModal: false
    })
  }

  handleDeletePopSubmit() {
    const PARAMS = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id: this.state.reference_id,
      instruction_id: 0
    }
    axios.get(PATIENT_INSTRUCTION_DELETE(PARAMS))
      .then(success => {
        this.setState({
          instructionList: null,
          deleteInstructionModal: false
        })
      });
  }

  onInstructionSelect = async(id, newInstruction, oldInstruction) => {
    let { instructionList } = this.state;

    var instructionExist = -1;
    instructionList.map((v, k) => {
      if (id != -1 && v.id == id) {
        instructionExist = k;
      }
      else if (v.instruction == newInstruction) {
        instructionExist = k;
      }
    })
    if (instructionExist != -1) {
      let intruct_is = await this.handleInstructionDelete(id);
      instructionList.splice(instructionExist, 1);
    }
    else {
      instructionList.push({ id: id, instruction: newInstruction });
    }
    this.setState({ instructionList })
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'custom_field_1') {
      initialText = this.state?.subjective_custom?.custom1 != undefined ? this.state?.subjective_custom?.custom1 : '';
    }
    if (info.identifier === 'custom_field_2') {
      initialText = this.state?.subjective_custom?.custom2 != undefined ? this.state?.subjective_custom?.custom2 : '';
    }
    if (info.identifier === 'custom_field_3') {
      initialText = this.state?.subjective_custom?.custom3 != undefined ? this.state?.subjective_custom?.custom3 : '';
    }
    if (info.identifier === 'custom_field_4') {
      initialText = this.state?.subjective_custom?.custom4 != undefined ? this.state?.subjective_custom?.custom4 : '';
    }
    if (info.identifier === 'custom_field_5') {
      initialText = this.state?.subjective_custom?.custom5 != undefined ? this.state?.subjective_custom?.custom5 : '';
    }
    if (info.identifier === 'custom_field_parent_1') {
      initialText = this.state?.parent_customdata?.parent_custom1 != undefined ? this.state?.parent_customdata?.parent_custom1 : '';
    }
    if (info.identifier === 'custom_field_parent_2') {
      initialText = this.state?.parent_customdata?.parent_custom2 != undefined ? this.state?.parent_customdata?.parent_custom2 : '';
    }
    if (info.identifier === 'custom_field_parent_3') {
      initialText = this.state?.parent_customdata?.parent_custom3 != undefined ? this.state?.parent_customdata?.parent_custom3 : '';
    }
    if (info.identifier === 'custom_field_parent_4') {
      initialText = this.state?.parent_customdata?.parent_custom4 != undefined ? this.state?.parent_customdata?.parent_custom4 : '';
    }
    if (info.identifier === 'custom_field_parent_5') {
      initialText = this.state?.parent_customdata?.parent_custom5 != undefined ? this.state?.parent_customdata?.parent_custom5 : '';
    }
    if (info.identifier === 'review_notes') {
      initialText = this.state?.reviewTxt != undefined ? this.state?.reviewTxt : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        /* if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        } */

      });
    } else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          /* this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          })) */
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      /* if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      } */

      speechService.init(this.state.speech);
    });

  }

  handleOnFocus = (e) => {
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "custom_field_1" ? this.state?.subjective_custom?.custom1 : identifierValue === "custom_field_2" ? this.state?.subjective_custom?.custom2 : identifierValue === "custom_field_3" ? this.state?.subjective_custom?.custom3 : identifierValue === "custom_field_4" ? this.state?.subjective_custom?.custom4 : identifierValue === "custom_field_5" ? this.state?.subjective_custom?.custom5 :
          identifierValue === "custom_field_parent_1" ? this.state?.parent_customdata?.parent_custom1 : identifierValue === "custom_field_parent_2" ? this.state?.parent_customdata?.parent_custom2 : identifierValue === "custom_field_parent_3" ? this.state?.parent_customdata?.parent_custom3 : identifierValue === "custom_field_parent_4" ? this.state?.parent_customdata?.parent_custom4 : identifierValue === "custom_field_parent_5" ? this.state?.parent_customdata?.parent_custom5 : identifierValue ? 'review_notes' : ''
      },
      /* checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      } */
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

  saveCustomSubjective = async e => {
    const { patient, subjective_custom } = this.state;
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    if (subjective_custom && subjective_custom != null) {
      if (name == 'custom_field_1' && value != undefined && value != null) { subjective_custom.custom1 = value; }
      if (name == 'custom_field_2' && value != undefined && value != null) { subjective_custom.custom2 = value; }
      if (name == 'custom_field_3' && value != undefined && value != null) { subjective_custom.custom3 = value; }
      if (name == 'custom_field_4' && value != undefined && value != null) { subjective_custom.custom4 = value; }
      if (name == 'custom_field_5' && value != undefined && value != null) { subjective_custom.custom5 = value; }
      await this.setState({
        subjective_custom: subjective_custom
      });

      var PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: HOMECARE_REFERENCE_TYPE,
        patient_id: patient.patient_id,
        doctor_id: LS_SERVICE.get('staff_id'),
        [name]: value
      }
      axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
    }
  }

  saveParentCustomSubjective = async e => {
    const { patient, parent_customdata } = this.state;
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    if (parent_customdata && parent_customdata != null) {
      if (name == 'custom_field_parent_1' && value != undefined && value != null) { parent_customdata.parent_custom1 = value; }
      if (name == 'custom_field_parent_2' && value != undefined && value != null) { parent_customdata.parent_custom2 = value; }
      if (name == 'custom_field_parent_3' && value != undefined && value != null) { parent_customdata.parent_custom3 = value; }
      if (name == 'custom_field_parent_4' && value != undefined && value != null) { parent_customdata.parent_custom4 = value; }
      if (name == 'custom_field_parent_5' && value != undefined && value != null) { parent_customdata.parent_custom5 = value; }
      await this.setState({
        parent_customdata: parent_customdata
      });

      var PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: HOMECARE_REFERENCE_TYPE,
        patient_id: patient.patient_id,
        doctor_id: LS_SERVICE.get('staff_id'),
        [name]: value
      }
      axios.post(PARENT_CUSTOM_STORE, qs.stringify(PARAMS))
    }
  }

  admissionListLoad () {
    if ((!this.state.admissionListData || this.state.admissionListData.length === 0)) {
      let htmlData = (
        <tr className="view_details">
          <td className="inner_table text-center" colSpan={7}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }

    return this.state.admissionListData.map((result, i) => {
      let htmlData = (
        <tr>
          <td>{result.admissionRequestNo}</td>
          <td>{result.admissionRequestby}</td>
          <td>{moment(result.enteredDate).format('YYYY-MM-DD')}</td>
          <td>{result.caseTypeName}</td>
          <td>{result.admittingPractitionerName}</td>
          <td>{result.admittingSpecialityName}</td>
          <td>{result.remarks}</td>
        </tr>
      )
      return htmlData;
    });
  } catch (err) {
    console.log('admissionListData: ', err);
  }

  getCustomSubjectiveData = () => {
    const reference_id = this.props.reference_id;


    if (reference_id) {
      axios.get(SUBJECTIVE_INFRMATION_CUSTOM({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: reference_id }))
        .then(res => {
          this.setState({
            subjective_custom: res.data.custom_data || {}
          })
        })
    }
  }
  getCustomParentData = () => {
    const reference_id = this.props.reference_id;


    if (reference_id) {
      axios.get(PARENT_INFRMATION_CUSTOM({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: reference_id }))
        .then(res => {
          this.setState({
            parent_customdata: res.data.custom_data || {}
          })
        })
    }
  }

  getReferralDetails = () => {
    const patient_id = this.props.patient_id;
    const reference_id = this.props.reference_id;


    // const doctor_id = LS_SERVICE.get('staff_id');
    const doctor_id = LS_SERVICE.get('staff_id')


    const PARAMS = {
      patient_id: patient_id,
      // speciality_id: LS_SERVICE.get('speciality_id') || null,
      speciality_id: LS_SERVICE.get('speciality_id') || null,
      reference_id: reference_id,
      doctor_id: doctor_id,
      reference_type: HOMECARE_REFERENCE_TYPE
    }

    axios.post(REFERRAL_DETAILS, qs.stringify(PARAMS))
      .then(res => {
        if (res.data.status && res.data.data.length > 0) {
          this.setState({
            referralDetailsArr: res.data.data
          })
        }
      })
  }

  getPatientData = async _ => {
    const reference_id = this.props.reference_id;
    var reference_type = await LS_SERVICE.get('reference_type');
    await axios.get(PATIENT_DATA({ reference_id, reference_type }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
        }
        const reviewTxt = success.data.data.results.visit.review_notes
        const is_recall_doctor = success.data.data.results?.qmsToken?.is_recall_doctor
        this.setState({
          patient,
          reviewTxt,
          is_recall_doctor
        }, () => {
          this.initialSetup();
        })
      })
      .catch(err => console.log(err))
  }

  initialSetup = () => {
    const { patient, hospital_id } = this.state;
    const PARAMS = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      patient_id: patient.patient_id,
      reference_id: patient.qms_token_id,
      hospital_id
    }
    axios.post(SOAP_OBJECTIVE_DETAILS, qs.stringify(PARAMS))
      .then(async res => {

        const { details, field_status } = res.data;
        let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;

        let followUpType = null;
        let followUpDays = null;
        if (details.followUp !== null) {
          followUpType = details.followUp?.followUpType;
          followUpDays = details.followUp?.followUpDays;

          if (followUpType !== 0 && followUpType !== undefined) {
            followUpRadio = -1;
            otherVal = followUpDays;
            follow_up_Others = DURATION_TYPE_OBJECT_REVERSE[followUpType]?.toLowerCase();
          } else if (followUpDays !== undefined) {
            follow_up_days = followUpDays;
            followUpRadio = followUpDays
          }
        }

        const bookNowAllowFn = _ => {
          if (otherVal !== null || follow_up_days !== null) {
            if (otherVal > 0) return false;
            if (follow_up_days > 0) return false;
          }
          return true;
        }

        if(details.case_type != null && details.case_type == 1)
        {
          this.setState({
            surgeryButtonEnabled : true
          });
          await this.getExisitingSurgeries();
        }

        this.setState({
          details,
          constants: details.constants,
          field_status,
          dataLoading: false,
          physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
          admissionChecked: details?.admissionAdvised == 1 ? true : false,
          icu_days: details.icu_days > 0 ? details.icu_days : '',
          case_type: details.case_type,
          ward_days: details.ward_days > 0 ? details.ward_days : '',
          physioText: details?.physiotherapyComment ?? '',
          admissionComment: details?.admissionComment ?? '',
          isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
          admissionAdvisedDisabled: details?.admissionPayloadStatus == 1 ? true : false,
          followUpRadio,
          otherVal,
          follow_up_Others,
          follow_up_days,
          bookNowAllow: bookNowAllowFn(),
        })
      })
      .catch((err) => {
        console.log(err.data)
      })
  }

  handleInstructionDelete = async (id) => {
    const PARAMS = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id: this.state.reference_id,
      instruction_id: id
    }
    await axios.get(PATIENT_INSTRUCTION_DELETE(PARAMS))
      .then(success => {
        if (success.data.status) {
          return true;
        }
      });
  }

  loadData = () => {
    let { constants, favourite, intuitive } = this.state;

    const drugsConstantsPromise = axios.get(DRUGS_CONSTANT);

    const testConstantsPromise = axios.get(LAB_TEST_CONSTANTS);

    let drug_params = {search: ''};
    if(LS_SERVICE.get("user_type") == USERTYPES.admin){
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    drug_params.store_id = LS_SERVICE.get("STORE_ID");
    drug_params.facility_id = LS_SERVICE.get("FACILITY_ID");
    drug_params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    const DrugsFavourite = LS_SERVICE.get("favouriteMedicines") || [];

    let test_params = {};
    if(LS_SERVICE.get("user_type") == USERTYPES.admin){
      test_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
      test_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    if(APP_ENV == 'mhea'){
      test_params.active = true;
      test_params.facility_id = LS_SERVICE.get('FACILITY_ID');
      test_params.filter_service_type = 'I,P,O';
      test_params.doctor_id = LS_SERVICE.get('staff_id');
    }
    const testsFavouritePromise = axios.post(LAB_TEST_SEARCH, qs.stringify(test_params));

    const intuitiveConstantsPromise = axios.get(INTUITIVE_CONSTANTS);

    axios.all([drugsConstantsPromise, testConstantsPromise, testsFavouritePromise, intuitiveConstantsPromise]).then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseFour = responses[2];
        const responseFive = responses[3];

        if (responseOne.data.hasOwnProperty("data") === true) {
          constants = responseOne.data.data;
        }

        if (responseTwo.data.hasOwnProperty("data") === true) {
          constants["radiologyViews"] = responseTwo.data.data;
        }

        favourite.drugs = DrugsFavourite

        if (responseFour.data.hasOwnProperty("test_list") === true) {
          var testList = responseFour.data.test_list;
          var finaltestlist = []
          for (var index = 0; index < testList.length; index++) {
            if(testList[index].patientClassOp == true){
              finaltestlist.push(testList[index])
            }
          }
          favourite.tests = finaltestlist;
        }
        else{
          favourite.tests = []
        }

        if (responseFive.data.hasOwnProperty("details") === true) {
          intuitive = responseFive.data.details;
        }

        this.subjectiveChange();

        this.setState({
          constants: constants,
          favourite: favourite,
          intuitive: intuitive
        });
      })
    );
  };

  handleOnChange = (e, callBack = null) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      bookNowAllow: false
    }, () => {
      if (callBack !== null) {
        callBack();
      }
    })
  }

  viewMedicineData(){
    if (this.medicineCollapseRef.current?.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  viewTestData(){
    if (this.testCollapseRef.current?.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }

  loadInstructionData = () => {
    const doctor_id = LS_SERVICE.get('staff_id');
    axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${this.state.reference_id}&referenceType=${HOMECARE_REFERENCE_TYPE}`
    ).then(success => {
      if (success.data.supportingFiles) {
        this.setState({
          allSupportingMaterial: success.data.supportingFiles,
          supportingMaterial: success.data.supportingFiles.filter(file => file.selected)
        })
      }
    }).catch(err => console.log(err))

    axios.get(`${INSTRUCTION_SEARCH}?reference_type=${HOMECARE_REFERENCE_TYPE}&reference_id=${this.state.reference_id}&doctor_id=${doctor_id}`
    ).then(success => {
      var selectedInstructions = [];
      for (var selectedInstruction of success.data.data.details) {
        if (selectedInstruction.selected == true) {
          selectedInstructions.push(selectedInstruction)
        }
      }
      // console.log("selectedInstructions",selectedInstructions,this.instructionCollapseRef,this.medicineComponentRef);
      this.setState({
        instructionList: selectedInstructions,
      });
    }).catch(err => console.log(err))
  }

  openMedicineComponent = async () => {
    this.handleTabClick('plan_medicine')
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  openTestComponent = async () => {
    this.handleTabClick('plan_test')
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }

  updateInstructionFromParent = async (data) => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
    });
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.loadInstructionData();
  }

  subjectiveChange = async (data) => {
    var intuitive_data = {};
    var cps;

    var cc_keyword = this.state.cc_keyword;
    var internal_notes_keyword = this.state.internal_notes_keyword;
    var clinical_notes_keyword = this.state.clinical_notes_keyword;
    if (!data || data == 'cc_hpi') {
      cc_keyword = [];
      var cc_hpi = await axios.get(SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: this.props.reference_id }));
      var cc_hpi_array = cc_hpi?.data?.data?.details || [];
      console.log(cc_hpi_array);
      for (var cc_hpi_data of cc_hpi_array) {
        if (cc_hpi_data?.description && cc_hpi_data?.description != null && cc_hpi_data?.description != '') {
          if (cc_hpi_data?.subjective_id == 0) {
            this.setState({
              disableCompleteButton: false
            });
          }
          var cc_keyword_full = this.cleanKeyword(cc_hpi_data?.description)
          if (cc_keyword_full?.length > 0) {
            cc_keyword = cc_keyword.concat(cc_keyword_full);
          }
        }
        else if (cc_hpi_data?.subjective_id == 0) {
          this.setState({
            disableCompleteButton: true
          });
        }
      }
      // const { medicineDataEmpty } = this.state;
      // if (medicineDataEmpty == true) {
      //   this.setState({
      //     disableCompleteButton: true,
      //     disableConfirmButton: true
      //   });
      // }
      this.setState({
        cc_keyword: cc_keyword
      });
    }
    if (!data || data == 'internal_notes') {
      internal_notes_keyword = [];
      var internal_notes = await axios.get(SUBJECTIVE_INFRMATION_INTERNAL_NOTES({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: this.props.reference_id }));
      if (internal_notes?.data?.data?.internal_notes && internal_notes?.data?.data?.internal_notes != null && internal_notes?.data?.data?.internal_notes != '') {
        var internal_notes_keyword_full = this.cleanKeyword(internal_notes?.data?.data?.internal_notes)
        if (internal_notes_keyword_full?.length > 0) {
          internal_notes_keyword = internal_notes_keyword.concat(internal_notes_keyword_full)
        }
      }
      this.setState({
        internal_notes_keyword: internal_notes_keyword
      });
    }
    if (!data || data == 'clinical_notes') {
      clinical_notes_keyword = [];
      var clinical_notes = await axios.get(SUBJECTIVE_INFRMATION_CLINICAL_NOTES({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id: this.state.reference_id }));
      if (clinical_notes?.data?.data?.clinical_notes && clinical_notes?.data?.data?.clinical_notes != null && clinical_notes?.data?.data?.clinical_notes != '') {
        var clinical_notes_keyword_full = this.cleanKeyword(clinical_notes?.data?.data?.clinical_notes)
        if (clinical_notes_keyword_full?.length > 0) {
          clinical_notes_keyword = clinical_notes_keyword.concat(clinical_notes_keyword_full)
        }
      }
      this.setState({
        clinical_notes_keyword: clinical_notes_keyword
      });
    }
    if (this.state.ss_configuration?.some(o => (o?.id === 4 && o?.configuration?.[this.state.consult_type + '_screen']?.selected == 'Y' && o?.custom?.value == 'Related'))) {
      await this.setState({
        cpDiagnosisType: 'Related'
      })
      var keyword = cc_keyword.concat(internal_notes_keyword, clinical_notes_keyword);
      var keyword_string = keyword.join()

      var CP_PARAMS = {
        hospital_id: LS_SERVICE.get("slot_hospital_id"),
        speciality_id: LS_SERVICE.get("speciality_id"),
        logined_id: LS_SERVICE.get("staff_id"),
        login_role: 'doctor',
        hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
      }
      cps = await axios.post(TEMPLATE_FILTER, qs.stringify(CP_PARAMS));
      if (keyword_string?.split(' ')?.length >= 1) {
        var uniq = [...new Set(keyword)];
        var filtered_keyword= []
        for (var kdata of uniq){
          if(kdata?.length >= 3){
            filtered_keyword.push(kdata)
          }
        }
        var PARAMS = {
          logined_id: LS_SERVICE.get("staff_id"),
          hospital_id: LS_SERVICE.get("slot_hospital_id"),
          speciality_id: LS_SERVICE.get("speciality_id"),
          hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
          login_role: 'doctor',
          keywords: filtered_keyword
        }
        intuitive_data = await axios.post(INTUITIVE_DIAGNOSIS_AND_CP, qs.stringify(PARAMS));

        await this.setState({
          suggestedCP: intuitive_data?.data?.data?.templates || [],
          suggestedDiagnosis: intuitive_data?.data?.data?.diagnosis || [],
        })
      }
      else {
        await this.setState({
          suggestedCP: [],
          suggestedDiagnosis: [],
        })
      }
    }
    else if (this.state.ss_configuration?.some(o => (o?.id === 4 && o?.configuration?.[this.state.consult_type + '_screen']?.selected == 'Y' && o?.custom?.value == 'Favorites'))) {
      var CP_PARAMS = {
        hospital_id: LS_SERVICE.get("slot_hospital_id"),
        speciality_id: LS_SERVICE.get("speciality_id"),
        logined_id: LS_SERVICE.get("staff_id"),
        login_role: 'doctor',
        hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
      }
      var intuitive_data = {
        data: {
          data: {
            templates: [],
            diagnosis: []
          }
        }
      }
      cps = await axios.post(TEMPLATE_FILTER, qs.stringify(CP_PARAMS));
      if (cps?.data?.data?.templates && cps?.data?.data?.templates?.length > 0) {
        for (var cp of cps?.data?.data?.templates) {
          if (cp?.is_favorite == '0' || cp?.is_favorite == null || cp?.is_favorite == '') {
            // DO NOTHING
          }
          else {
            var fav_doc = cp?.is_favorite?.split(',').map(Number);
            if (fav_doc.includes(LS_SERVICE.get("staff_id"))) {
              intuitive_data.data.data.templates.push(cp)
            }
          }
        }
      }
      await this.setState({
        cpDiagnosisType: 'Favorites'
      })

      var fav_diagnosis = await axios.post(SEARCH_DIAGNOSIS);
      intuitive_data.data.data.diagnosis = fav_diagnosis.data
      await this.setState({
        suggestedCP: intuitive_data?.data?.data?.templates || [],
        suggestedDiagnosis: intuitive_data?.data?.data?.diagnosis || [],
      })
    }
    var allCP = [];
    var otherCP = [];
    if (intuitive_data?.data?.data?.templates?.length > 0) {
      for (var cp of cps?.data?.data?.templates) {
        allCP.push(cp)
        if (intuitive_data?.data?.data?.templates.some(t => t.template_id === cp.template_id)) {
          // NOTHING TO DO
        }
        else {
          otherCP.push(cp)
        }
      }
    }
    else {
      if (cps?.data?.data?.templates?.length > 0) {
        allCP = cps?.data?.data?.templates;
        otherCP = cps?.data?.data?.templates;
      }
    }

    await this.setState({
      allCP: allCP,
      otherCP: otherCP,
    })
  }

  cleanKeyword(keyword) {
    var finalKeyword = []

    // convert the whole keyword to smallcase
    keyword = keyword.toLowerCase();

    // remove leading/trailing whitespace and multiple spaces
    keyword = keyword.trim();
    keyword = keyword.replace(/[^a-zA-Z\,\s]/g, ",");
    keyword = keyword.replace(/\.+/g, ",");
    keyword = keyword.replace(/\s\s+/g, " ");
    keyword = keyword.replace(/\,\,+/g, ",");


    console.log(this.state?.intuitive)
    var final_filtered_keyword = [];
    if(Object.keys(this.state?.intuitive).length > 0){
      // remove excluded words
      for (var exclusion of this.state?.intuitive?.exclusion) {
        var regex_defined1 = new RegExp("\\W" + exclusion.word + "\\W", "g")
        keyword = keyword.replace(regex_defined1, " ");
        var regex_defined2 = new RegExp("^" + exclusion.word + "\\W", "g")
        keyword = keyword.replace(regex_defined2, " ");
        var regex_defined3 = new RegExp("\\W" + exclusion.word + "$", "g")
        keyword = keyword.replace(regex_defined3, " ");
      }

      // replace all dividers with comma
      for (var split of this.state?.intuitive?.split) {
        if (split.type == 'word') {
          var regex_defined1 = new RegExp("\\W" + split.word + "\\W", "g")
          keyword = keyword.replace(regex_defined1, ",");
          var regex_defined2 = new RegExp("^" + split.word + "\\W", "g")
          keyword = keyword.replace(regex_defined2, ",");
          var regex_defined3 = new RegExp("\\W" + split.word + "$", "g")
          keyword = keyword.replace(regex_defined3, ",");
        }
        else if (split.type == 'symbol') {
          var regex_defined1 = new RegExp(split.word, "g")
          keyword = keyword.replace(regex_defined1, ",");
        }
        else if (split.type == 'escapechar') {
          var regex_defined1 = new RegExp(split.word, "g")
          keyword = keyword.replace(regex_defined1, ",");
        }
      }

      // replace unwanted spaces and commas
      keyword = keyword.trim();
      keyword = keyword.replace(/\s+\,+\s+/g, ",");
      keyword = keyword.replace(/\,+\s+/g, ",");
      keyword = keyword.replace(/\s+\,+/g, ",");

      // convert into array
      finalKeyword = keyword.split(/[,]+/);

      var filtered_keywords = finalKeyword.filter(element => {
        return element !== '';
      });

      // remove negetive values from keyword array
      for (var filtered_keyword of filtered_keywords) {
        var negetive_included = 'N';
        for (var negetive of this.state.intuitive?.negetive) {
          var regex_defined1 = new RegExp("\\b" + negetive.word + "\\b", "g")
          if (filtered_keyword.search(negetive.word) >= 0) {
            negetive_included = 'Y'
            break;
          }
          else {
            negetive_included = 'N'
          }
        }
        if (negetive_included == 'N') {
          final_filtered_keyword.push(filtered_keyword)
        }
      }
    }
    return final_filtered_keyword;
  }

  updateInstructionsData = async (data) => {
    const PARAMS = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id: this.state.reference_id,
      type: 'instruction',
      instruction: data,
      supporting_materials: []
    }

    let instructionPromise;
    if (this.state.allowEdit) {
      instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    }
    this.loadInstructionData();
    this.handleModalPopup(null, 'showInstructionModal');
  }

  beforeChange = (slick, currentSlide, nextSlide) => {
    let documentdata = this.state.patient_docments[currentSlide].src
    this.setState({
      documentURL: documentdata
    })
  }

  selectAllFiles = (e) => {
    this.setState({ downloadAllfiles: e.target.checked });
  }

  downloadFiles = (e) => {
    e.preventDefault();
    this.setState({
      download_file_loading: true
    })
    if (this.state.downloadAllfiles) {
      this.state.patient_docments.map((element, index) => {
        this.downloadDocument(element.src, index);
      });
    } else {
      this.downloadDocument(this.state.documentURL);
    }
  }

  downloadDocument = (file_url, index = '') => {
    let file_extention = file_url.split('.').pop();
    let file_name = "document_" + moment().format('D_MMM_YYYY') + index + "." + file_extention;
    axios.post(GET_ARRAY_BUFFER_FORM_URL, { file_url }, { responseType: 'arraybuffer' }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      let file = document.createElement('a');
      file.href = url;
      file.download = file_name;
      file.click();
      this.setState({
        download_file_loading: false
      })
    });
  }

  // For Modal popup
  handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
    if (e !== null)
      e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    },
      () => {
        const { viewDocumentModal } = this.state;
        this.subjectiveChange();
        if (viewDocumentModal) {
          this.carouselRef.current.goTo(data);
          let documentdata = this.state.patient_docments[data].src
          this.setState({
            documentURL: documentdata
          })
        }

        this.setState({
          actionText: "Add", editData: null, editIndex: -1
        }, () => {
          const { showVitalsModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal, deleteConfirmModal, showDrugAllergyModal, showDrugPolyPharmacyModal, showSupportingModal } = this.state;
          if (!showVitalsModal) {
            this.state?.vitalsConstants?.vitals_details?.map((v, k) => {
              this.setState({ [v.id]: null });
            });

            if (this.vitalFormRef?.current !== null) {
              this.vitalFormRef?.current?.resetFields();
            }
          } else {

            let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
            /* For dispalying fields on basis of dropdown select*/
            let defaultVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
            let selectedVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => {
              let item_arr = [];
              item_arr = item.speciality_id != null ? item.speciality_id.split(',') : item_arr
              // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
              if (LS_SERVICE.get('speciality_id') && item_arr.indexOf(LS_SERVICE.get('speciality_id')) && item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
                return true;
            });

            if (selectedVitalGroup == undefined) {
              selectedVitalGroup = defaultVitalGroup;
            }

            if (typeof selectedVitalGroup != 'undefined' && selectedVitalGroup != '') {
              seletctedSpecialityIdID = selectedVitalGroup.id
              this.setState({
                seletctedSpecialityIdID: seletctedSpecialityIdID
              })
            }
            // let seletctedShowingVitalGroup1 = Object.values(this.state.constants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
            let showingVitals = selectedVitalGroup.vital_sign;

            let displayShowingVitals1 = [];
            let displayVitals = [];
            showingVitals.map((v, k) => {
              displayShowingVitals1.push(v.id);
              displayVitals.push(v.vital_id);
            });

            displayVitals = displayVitals.toString();

            this.setState({
              displayShowingVitals: displayShowingVitals1,
              displayVitals: displayVitals,
              vitalsType: selectedVitalGroup.id,
              vitalsInput: showingVitals,
            })

            // orderVitalsService.init(displayShowingVitals1);
            let { patientHeight, patientWeight, patient } = this.state;

            if (patient.age > HOSPITALDEFAULTS.age) {
              if (this.vitalFormRef.current !== null) {

                this.vitalFormRef.current.setFieldsValue({
                  V001: patientHeight,
                  V002: patientWeight
                });
                this.handleBmiCalculate();
              }
            }
            AntSelectLabels();
          }

          if (showAssessmentModal) {

            if (actionType === 'editAssessment') {
              this.setState({
                actionText: "Edit",
                editData: this.state.assessmentData[objId],
                editIndex: objId
              })
              AntSelectLabels();
            }

          }
          if (showMedicineModal) {
            if (actionType === 'editMedicine') {
              this.setState({ actionText: "Edit" })
            }

            if (data !== null) {
              let editData = this.state.drugsData[objId];
              editData.previousEndDate = null;
              for (let index = (objId - 1); index >= 0; index--) {
                if (this.state.drugsData[index].end_date != null && editData.previousEndDate == null)
                  editData.previousEndDate = this.state.drugsData[index].end_date;
                if (editData.start_date) {
                  editData.end_date = moment(editData.start_date, 'YYYY-MM-DD').add(editData.number_of_days - 1, 'days').format('YYYY-MM-DD');
                }

              }
              this.setState({
                actionText: "Edit",
                editData: editData,
                editIndex: objId
              })
            }
          }

          if (showInstructionModal) {
            // const { allInstructionsData } = this.state;
            // this.setState({
            //   isNewInstruction: false,
            //   instructionOption: allInstructionsData
            // });

          }


          if (deleteConfirmModal) {
            // this.setState({ actionType, objId });
            // For Assessment
            if (actionType === 'deleteAssessment') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete assessment?' });
            }

            // For Medicines
            if (actionType === 'deleteMedicine') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete medicine?' });
            }

            // For Tests
            if (actionType === 'deleteTest') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
            }

          }

          if (showDrugAllergyModal) {
            this.setState({ allergiesMessage: data.message, deleteObjId: data.type == 'all' ? data.deleteObj : objId })
          }

          if (showDrugPolyPharmacyModal) {
            this.setState({ polyPharmacyMessage: data.message, deleteObjId: data.type == 'all' ? null : objId })
          }


        })
      })

  }

  getMaterialData = (materialData) => {
    this.setState({ materialList: materialData });
  }

  updateSupportingMaterial = async (data) => {
    var selectedInstructions = [];
    for (var selectedInstruction of data) {
      if (selectedInstruction.selected == true) {
        selectedInstructions.push({
          id: selectedInstruction.id,
          file_name: selectedInstruction.fileName,
          file_url: selectedInstruction.fileUrl
        })
      }
    }
    const PARAMS = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id: this.state.reference_id,
      type: 'sm',
      instruction: [],
      supporting_materials: selectedInstructions
    }
    if (this.state.allowEdit) {
      await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    }
    this.loadInstructionData();
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
    } else {
      is_practicing = false;
    }
    this.setState({
      isPracticing: is_practicing,
    }, () => {
      this.handleModalPopup(null, 'isPracticingModal');
    });
  }

  handleRadioChange = (e, callBackFunName = "") => {
    e.preventDefault()
    const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }

    const { name, value } = e.target;
    this.setState({
      [name]: value
    }, () => {
      if (callBackFunName !== "") {
        if(callBackFunName == "admissionSave" && value == false)
        {
          this.setState({
            surgeryRequired : false,
          });
        }
        if(callBackFunName == "admissionSave" && value == true)
        {
          this.getActiveAdmissionRequest();
        }
        this[callBackFunName]();
      }
    })
  }

  handleAdmissionAdvicedCheckbox = e => {
    const { isPracticing, isAdmissionAdvisedPriority } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }

    let { name, checked } = e.target;
    this.setState({
      [name]: checked,
      isAdmissionAdvisedPriority: !checked ? false : isAdmissionAdvisedPriority
    }, this.admissionSave)
  }

  handleAdmissionAdvicedComment = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    }, this.admissionSave)
  }

  admissionSave = () => {
    //this.setState({ disableConfirmButton: true });
    const { patient, admissionChecked, isAdmissionAdvisedPriority, admissionComment, icu_days, ward_days, case_type } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: HOMECARE_REFERENCE_TYPE,
      admission_advised: admissionChecked ? 1 : 0,
      admission_comment: admissionComment,
      icu_days: icu_days,
      case_type: case_type,
      ward_days: ward_days,
      priority: isAdmissionAdvisedPriority ? 1 : 0
    }
    if (!admissionChecked) {
      this.setState({
        admissionComment: "",
        icu_days: "",
        case_type: null,
        ward_days: "",
      })
      PARAMS.admission_comment = "";
      PARAMS.icu_days = "";
      PARAMS.case_type = null;
      PARAMS.ward_days = "";
    }
    axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS))
      .then(res => {
        //this.setState({ disableConfirmButton: false });
      })
  }

  handleFollowUpRadio = (e) => {
    const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }

    const { value, title } = e.target;
    this.setState({
      followUpRadio: value,
      follow_up_days: title !== '-1' ? +title : title,
      bookNowAllow: false,
    }, () => {
      const { follow_up_days } = this.state;
      if (follow_up_days === '-1') return;
      this.handleFollowUpApi();
    })
  }

  getActiveAdmissionRequest = async () => {
    const { patient } = this.state;
    let PARAMS = {
      RegistrationId: parseInt(patient.mhea_patient_id),
      EnterpriseId: parseInt(LS_SERVICE.get('ENTERPRISE_ID')),
      FacilityId: parseInt(LS_SERVICE.get('FACILITY_ID'))
    }
    axios.post(ACTIVE_ADMISSION_REQUEST, qs.stringify(PARAMS))
      .then(res => {
        if(res.data?.data && res.data.data.length > 0)
        {
          this.setState({ admissionListData: res.data.data, viewAdmissionGrid : true });
        }
      })
  }


  handleFollowUpApi = _ => {
    const { follow_up_days, followUpRadio, patient, follow_up_Others, otherVal } = this.state;

    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: HOMECARE_REFERENCE_TYPE,
      patient_id: patient.patient_id,
      follow_up_days: followUpRadio != '-1' ? followUpRadio : +otherVal,
      follow_up_type: followUpRadio != '-1' ? follow_up_days : DURATION_TYPE_OBJECT[follow_up_Others.toLowerCase()]
    }

    axios.post(FOLLOWUP_UPDATE, qs.stringify(PARAMS));
  }

  handleToggleAdmission = (e) => {
    if (this.state.admissionAdvisedDisabled)
    {
      return false;
    }
    this.setState({
      isAdmissionAdvisedPriority: e
    }, this.admissionSave)

  }

  handleViewPresc = async (e) => {
    // var selectedInstructions = [];
    // for(var selectedInstruction of this.state.materialList){
    //   if(selectedInstruction.selected == true){
    //     selectedInstructions.push({
    //       id: selectedInstruction.id,
    //       file_name: selectedInstruction.fileName,
    //       file_url: selectedInstruction.fileUrl
    //     })
    //   }
    // }
    // const PARAMS = {
    //   reference_type: HOMECARE_REFERENCE_TYPE,
    //   reference_id: this.state.reference_id,
    //   type: 'sm',
    //   instruction: [],
    //   supporting_materials: selectedInstructions
    // }
    // let instructionPromise;
    // if (this.state.allowEdit){
    //   instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    // }
    e.preventDefault()
    const { patient, hospital_id } = this.state;
    this.props.history.push({
      pathname: `/patient/todays-visit/view-prescriptionss/${hospital_id}/${patient.patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient.qms_token_id}`,
      state: { patient }
    })
  }

  handleCompleteAppointment = async () => {
    const { patient, hospital_id, isPracticing, reviewNotes, reviewTxt, surgeryRequired } = this.state;

    if (surgeryRequired) {
      notification.error({
        message: 'Kindly add planned surgery details for the admission.',
        placement: 'topRight'
      });
      return false;
    }

    this.setState({
      isPrintLoader: true,
    });

    // var selectedInstructions = [];
    // for(var selectedInstruction of this.state.materialList){
    //   if(selectedInstruction.selected == true){
    //     selectedInstructions.push({
    //       id: selectedInstruction.id,
    //       file_name: selectedInstruction.fileName,
    //       file_url: selectedInstruction.fileUrl
    //     })
    //   }
    // }
    // const PARAMS = {
    //   reference_type: HOMECARE_REFERENCE_TYPE,
    //   reference_id: this.state.reference_id,
    //   type: 'sm',
    //   instruction: [],
    //   supporting_materials: selectedInstructions
    // }
    // let instructionPromise;
    // if (this.state.allowEdit){
    //   instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    // }

    await this.vitalAndKfComponentRef?.current?.storeVitalDetails();
    await this.chiefComponentRef?.current?.handleSubjectiveFormSubmit();

    if (!this.state.allowEdit) {
      this.props.history.push({
        pathname: `/patient-queue/${hospital_id}`,
      });
      return;
    }

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    var ipd_slots = LS_SERVICE.get("ipd_slots")
    var commonfacilityName = ipd_slots.find(item => item?.hospitalId == LS_SERVICE.get("FACILITY_ID"));

    const params = {
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id,
      hospital_id,
      print_pdf: 1,
      type: 'SS',
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: commonfacilityName?.facilityId || LS_SERVICE.get("FACILITY_ID"),
      facility_name: commonfacilityName?.facilityName || LS_SERVICE.get("FACILITY_NAME"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID"),
      enterprise_name: LS_SERVICE.get("ENTERPRISE_NAME"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      fromfacility: commonfacilityName?.hospitalName || LS_SERVICE.get("FACILITY_NAME"),
      fromfacilityid: commonfacilityName?.hospitalId || LS_SERVICE.get("FACILITY_ID"),
      //review_notes: reviewNotes == '' ? reviewTxt : reviewNotes
      review_notes: reviewTxt
    };

    // axios.post(PATIENT_CHECKOUT, {
    //   booking_id: patient.qms_token_id
    // });

    axios.post(COMPLETE_APPOINTEMENT, qs.stringify(params)).then((res) => {
      if (this.state?.details?.qms_details?.source == "qms") {
        let params = { status: 'complete', token_id: this.state?.details?.qms_details?.token_id, hospital_id: hospital_id }
        axios.post(UPDATE_QMS_STATUS, qs.stringify(params));
      }

      this.setState({
        isPrintLoader: false,
      });

      const browser = Bowser.parse(window.navigator.userAgent).browser.name;
      if (browser == "Safari") {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      } else {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}?reference=${patient.qms_token_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      }

      // this.props.history.push({
      //   pathname: `/patient-queue/${hospital_id}`,
      //   state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
      // });
    });
  };

  onbooknow = (submit = false) => {
    const { followUpRadio, patient, follow_up_Others, otherVal, hospital_id } = this.state;
    let date = new Date();
    let todayDate = date.getDate();
    let foramttedDate = '';
    if (followUpRadio !== -1) {
      let newDate = date.setDate(todayDate + parseInt(followUpRadio));
      foramttedDate = new Date(newDate).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-');
    } else {
      date = moment().add(+otherVal, follow_up_Others);
      foramttedDate = new Date(date._d).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-');
      if (!submit) this.handleFollowUpApi();
    }
    patient.id = patient.patient_id;

    if (submit) {
      LS_SERVICE.set('bookNowPatient', patient);
      this.props.history.push({
        pathname: `/patient/todays-visit/follow-up/${hospital_id}/${patient.patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient.qms_token_id}/${foramttedDate}`,
        state: { patient }
      })
    }
  }

  physiotherapySave = _ => {
    this.setState({ disableConfirmButton: true });
    const { patient, physioText, physiotherapy_advised } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: HOMECARE_REFERENCE_TYPE,
      physiotherapy_advised: physiotherapy_advised === 2 ? 0 : 1,
      physiotherapy_comment: physioText
    }
    if (physiotherapy_advised === 2) {
      this.setState({
        physioText: ""
      })
      PARAMS.physiotherapy_comment = "";
    }
    axios.post(PHYSIO_UPADTE, qs.stringify(PARAMS))
      .then(res => {
        this.setState({ disableConfirmButton: false });
      })
  }

  handleReferralDetails = () => {
    this.setState({
      isReferralModal: true
    });
  }
  reviewNote = async (e) => {
    const { name, value } = e.target
    await this.setState({
      // reviewNotes: value,
      reviewTxt: value
    })
    if (value != '') {
      e.target.parentElement.classList.add('hasdata');
    } else {
      e.target.parentElement.classList.remove('hasdata');
    }
  }

  printCompleteButtonDiabled(medicineDataEmpty) {
    this.setState({ medicineDataEmpty })
  }

  async saveNotesModel(e, model_type) {
    await this.setState({ notesModelActive: true })
    e.preventDefault();
    let { name, value } = e.target;
    let input_type = '';
    let content = value;
    if (name == 'custom_field_1') { input_type = 'cc1'; }
    if (name == 'custom_field_2') { input_type = 'cc2'; }
    if (name == 'custom_field_3') { input_type = 'cc3'; }
    if (name == 'custom_field_4') { input_type = 'cc4'; }
    if (name == 'custom_field_5') { input_type = 'cc5'; }
    doctorNotesService.init({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    });

    this.setState({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    })
  }

  saveTextBoxData(data) {
    let input_type = data[0].type;
    let arr = ['pc1','pc2','pc3','pc4','pc4','pc5'];
    if(arr.includes(input_type)){
      this.saveCustomSubjectiveParentFromDoctor(data, 'N');
    }else{
      this.saveCustomSubjectiveFromDoctor(data, 'N');
    }    
  }

  saveCustomSubjectiveParentFromDoctor(data, defalut_case_type) {
    const { patient, parent_customdata } = this.state;
    let value = data.map(obj => obj.content).join("\n");
    let name = '';
    let newString = '';
    let input_type = {
      pc1: 'custom_field_parent_1',
      pc2: 'custom_field_parent_2',
      pc3: 'custom_field_parent_3',
      pc4: 'custom_field_parent_4',
      pc5: 'custom_field_parent_5',
    }

    let obj ={
      custom_field_parent_1 : 'parent_custom1',
      custom_field_parent_2 : 'parent_custom2',
      custom_field_parent_3 : 'parent_custom3',
      custom_field_parent_4 : 'parent_custom4',
      custom_field_parent_5 : 'parent_custom5',
    }
    
    let type = data[0].type;
    name = input_type[type];
    let field_type = obj[name];
    if( defalut_case_type == 'Y' && parent_customdata[field_type] != "" && parent_customdata[field_type] != null){
      return;
     }
    newString = parent_customdata[field_type] !='' && parent_customdata[field_type] != null && parent_customdata[field_type] != undefined ? parent_customdata[field_type] + "\n" + value : value;
    parent_customdata[field_type] = defalut_case_type == 'Y' ?   parent_customdata[field_type] == "" || parent_customdata[field_type] == null ? data[0].content : parent_customdata[field_type] : newString ;
    
    this.setState({
      parent_customdata: parent_customdata
    });
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: HOMECARE_REFERENCE_TYPE,
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: newString
    }
    axios.post(PARENT_CUSTOM_STORE, qs.stringify(PARAMS))
    this.setState({ notesModelActive: false })
  }


  saveCustomSubjectiveFromDoctor(data, defalut_case_type) {
    const { patient, subjective_custom } = this.state;
    let value = data.map(obj => obj.content).join("\n");
    let name = '';
    let newString = '';
    let input_type = {
      cc1: 'custom_field_1',
      cc2: 'custom_field_2',
      cc3: 'custom_field_3',
      cc4: 'custom_field_4',
      cc5: 'custom_field_5',
    }

    let obj ={
      custom_field_1 : 'custom1',
      custom_field_2 : 'custom2',
      custom_field_3 : 'custom3',
      custom_field_4 : 'custom4',
      custom_field_5 : 'custom5',
    }
    
    let type = data[0].type;
    name = input_type[type];
    let field_type = obj[name];
    if( defalut_case_type == 'Y' && subjective_custom[field_type] != "" && subjective_custom[field_type] != null){
      return;
    }

    newString = subjective_custom[field_type] !='' && subjective_custom[field_type] != null && subjective_custom[field_type] != undefined ? subjective_custom[field_type] + "\n" + value : value;
    subjective_custom[field_type]= defalut_case_type == 'Y' ? subjective_custom[field_type] == "" || subjective_custom[field_type] == null ? data[0].content :subjective_custom[field_type] : newString ;


    this.setState({
      subjective_custom: subjective_custom
    });
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: HOMECARE_REFERENCE_TYPE,
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: newString
    }
    axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
    this.setState({ notesModelActive: false })
  }

  resetModel() {
    // this.setState({notesModel:false})
    // this.handelDefaultText();
  }

  resetNotesModel() {
    this.setState({ notesModelActive: false })
  }

  async saveNotesForParentModel(e, model_type) {
    await this.setState({ notesModelActive: true })
    e.preventDefault();
    let { name, value } = e.target;
    let input_type = '';
    let content = value;
    if (name == 'custom_field_parent_1') { input_type = 'pc1'; }
    if (name == 'custom_field_parent_2') { input_type = 'pc2'; }
    if (name == 'custom_field_parent_3') { input_type = 'pc3'; }
    if (name == 'custom_field_parent_4') { input_type = 'pc4'; }
    if (name == 'custom_field_parent_5') { input_type = 'pc5'; }
    doctorNotesService.init({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    });

    this.setState({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    })
  }

  async handelDefaultText() {
    let doctor_id = LS_SERVICE.get('staff_id');
    const { consult_type, ss_configuration} = this.state;

    let parent_obj ={
      pc1 :11,
      pc2 :12,
      pc3 :13,
      pc4 :14,
      pc5 :15,
    }

    let child_obj ={
      cc1 :11,
      cc2 :12,
      cc3 :13,
      cc4 :14,
      cc5 :15,
    }
    
    const res = await axios.get(GET_DEFAULT_TEXT({ doctor_id: doctor_id }));
    if (res.data.data.length > 0) {
      let data = res.data.data;
      this.setState({default_text:data});
      let arr = ['cc1', 'cc2', 'cc3', 'cc4', 'cc5','pc1' ,'pc2','pc3','pc4','pc5'];
      let parent_arr = ['pc1' ,'pc2','pc3','pc4','pc5'];
      let new_list = data.filter(obj => arr.includes(obj.type));
      new_list.map((data ,i) => {
        let arr = [];
        arr.push(data);
        if(parent_arr.includes(data.type)){
          if(ss_configuration.some(o => (o?.id === parent_obj[data.type] && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))){
            this.saveCustomSubjectiveParentFromDoctor(arr, 'Y');
          }         
        }else{
           if(ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === child_obj[data.type]  && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
           {
            this.saveCustomSubjectiveFromDoctor(arr, 'Y');
           }
        }
      })
    }

  }

  calculateAssessmentLength(data) {
    if (data && data.length > 0) {
      this.setState({ assessmentDataLength: data.length });
    } else {
      this.setState({ assessmentDataLength: 0 });
    }
  }

  showCP() {
    let flag = false;
    const { assessmentDataLength, medicineLength, testLength } = this.state;
    if (assessmentDataLength > 0 && (medicineLength > 0 || testLength > 0)) {
      flag = true;
    }
    return flag;
  }

  render() {
    if (this.state.instructionList?.length > 0) {
      if (this.instructionCollapseRef?.current?.classList.contains("collapsed")) {
        this.instructionCollapseRef?.current?.click();
      }
    }
    const { patient, ss_configuration, consult_type, details, field_status, dataLoading, physiotherapy_advised, physioText, admissionChecked, admissionComment, icu_days, ward_days, case_type, surgeryButtonEnabled, followUpRadio, isAdmissionAdvisedPriority, favourite, intuitive, constants, active_tab, patient_id, reference_id, hospital_id, referralDetailsArr, allowEdit, patient_note_doctor, patient_docments ,medicineDataEmpty} = this.state;
    var custom1 = {};
    var custom2 = {};
    var custom3 = {};
    var custom4 = {};
    var custom5 = {};
    var parent_custom1 = {};
    var parent_custom2 = {};
    var parent_custom3 = {};
    var parent_custom4 = {};
    var parent_custom5 = {};
    var field_status_patient_history = []

    // VARIABLE NAME MUST MATCH THE NAME IN THE DB
    var parent_variable = {
      vital_and_kf: null,
      patient_hx: null,
      subjective: null,
      diagnosis: null,
      medicine: null,
      test: null,
      instruction: null,
      supporting_material: null,
      referral: null,
      other: null,
      review: null,
      custom_field_parent_1: null,
      custom_field_parent_2: null,
      custom_field_parent_3: null,
      custom_field_parent_4: null,
      custom_field_parent_5: null,
    };
    var child_variable = {
      custom_field_1: null,
      custom_field_2: null,
      custom_field_3: null,
      custom_field_4: null,
      custom_field_5: null,
      clinical_notes: null,
      internal_notes: null,
      physiotherapy: null,
      admission: null,
      followup: null
    }

    if (ss_configuration) {
      var custom_parent = ss_configuration.find(o => o?.id === 3)
      if (custom_parent && custom_parent.sub_items) {
        custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      }
      if (ss_configuration.some(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom1 = ss_configuration.find(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom2 = ss_configuration.find(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom3 = ss_configuration.find(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom4 = ss_configuration.find(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom5 = ss_configuration.find(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (field_status?.patient_history?.length > 0) {
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "allergies"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "existing_conditions"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "surgical_hx"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "social_hx"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "family_hx"))
        }
        var child_others = ss_configuration.find(o => o?.id === 10).sub_items;
        child_variable.physiotherapy = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 18 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row mb-2'>
                <div className='col-6 d-flex align-items-center'>
                  <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">PHYSIOTHERAPY ADVISED </label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group disabled={!this.state.allowEdit} onChange={e => this.handleRadioChange(e, 'physiotherapySave')} name='physiotherapy_advised' value={physiotherapy_advised}>
                      <Radio value={1} name="physiotherepy_radio">Yes</Radio>
                      <Radio value={2} name="physiotherepy_radio">No</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={`col-6 mt-2 ${physiotherapy_advised == 2 ? "d-none" : ""}`} id="therapy_text">
                  <div className={`form-group mb-0 ${physioText != '' ? 'hasdata' : ''}`}>
                    <textarea disabled={!this.state.allowEdit} onInput={(e) => this.autoHeight(e)} rows={1} id="comment" className="form-control autoHeightText"
                      placeholder="Therapy Required" value={physioText} onBlur={this.physiotherapySave}
                      onChange={(e) => this.setState({ physioText: e.target.value })} />
                    <label htmlFor="comment">Therapy Required</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.admission = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 19 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row mb-2 mt-2'>
                <div className='col-4 d-flex align-items-center'>
                  <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">ADMISSION ADVISED </label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group disabled={this.state.admissionAdvisedDisabled} onChange={e => this.handleRadioChange(e, 'admissionSave')} name='admissionChecked' value={admissionChecked}>
                      <Radio value={true} name="admission_radio">Yes</Radio>
                      <Radio value={false} name="admission_radio">No</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {admissionChecked ? (
                  <>
                    <div className='col-2 d-flex align-self-center'>
                      <span className='ant_switch_button_sm phsio_advised' id="emer_elect">
                        <BootstrapSwitchButton
                          width={150} height={24}
                          size="xs"
                          checked={isAdmissionAdvisedPriority}
                          offlabel='Elective'
                          onlabel='Emergency'
                          offstyle="success"
                          onstyle="alert"
                          onChange={this.handleToggleAdmission}
                          disabled={this.state.admissionAdvisedDisabled}
                        />
                      </span>
                    </div>
                    <div className={`col-6 ${admissionChecked == false ? "d-none" : ""}`} id="therapy_text">
                      <div className={`form-group mb-0 ${admissionComment != '' ? 'hasdata' : ''}`}>
                        <textarea onInput={(e) => this.autoHeight(e)} rows={1} id="admission_comment" className="form-control autoHeightText"
                          placeholder="Therapy Required" value={admissionComment} onBlur={this.handleAdmissionAdvicedComment}
                          onChange={(e) => this.setState({ admissionComment: e.target.value })} />
                        <label htmlFor="admission_comment">Admission Advised Remark For Patient</label>
                      </div>

                    </div>
                  </>
                ) : ""}
              </div>
              :
              null
          }

          {admissionChecked ? (
            <>
              <div className='row my-2'>
                <div className='col-2'>
                    <div className={`form-group pl-0 ${ward_days != '' ? 'hasdata' : ''}`}>
                      <input type="text" id="ward_days" name="ward_days" value={ward_days} disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleOnTextChange} onBlur={this.handleOnTextChange} className="form-control onlynumbers" placeholder="Ward Days" />
                      <label htmlFor="ward_days">Ward Days</label>
                    </div>
                </div>
                <div className='col-2'>
                  <div className={`form-group pl-0 ${icu_days != '' ? 'hasdata' : ''}`}>
                    <input type="text" id="icu_days" name="icu_days" value={icu_days} disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleOnTextChange} onBlur={this.handleOnTextChange} className="form-control onlynumbers" placeholder="ICU Days" />
                    <label htmlFor="icu_days">ICU Days</label>
                  </div>
                </div>
                <div className='col-3'>
                  <div className={`form-group pt-1 ${case_type != null ? 'hasdata' : ''}`}>
                    <select style={{ backgroundColor : 'none !important' }} className="form-control mandatory-field case_type_select" value={this.state.case_type}
                      name="case_type" disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleCaseType}>
                      <option value={0}></option>
                      <option value={2}>Medical Management</option>
                      <option value={1}>Surgery</option>
                    </select>
                    <label htmlFor="case_type">Case Type</label>
                  </div>
                </div>
                <div className={`col text-right pr-5 ${admissionChecked == false ? "d-none" : ""}`} >
                  <a className={`btn btn-outline-primary small px-2 mr-2 ${surgeryButtonEnabled == false ? "d-none" : ""}`} id="surger_req" onClick={(e) => this.handleSurgeryRequest()}>+ SURGERY REQUEST</a>
                  <a className="btn btn-outline-primary small px-2 ml-2" id="admint_ord" onClick={(e) => this.handleAdmitOrder()}>+ INTERNAL REMARKS</a>
                </div>
              </div>
              </>
          ) : ""}
        </>
        child_variable.followup = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 20 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="row mb-2 mt-2" id="followup">
                <div className="col-12 col-xl" style={{ display: this.state.admissionChecked ? "none" : '' }}>
                  <label className="d-md-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">FOLLOW UP (NUMBER OF DAYS)</label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group onChange={this.handleFollowUpRadio} checked={followUpRadio} value={followUpRadio} disabled={!this.state.allowEdit}>
                      <Radio value={3} name="followUpRadio" title={'0'}>3</Radio>
                      <Radio value={5} name="followUpRadio" title={'0'}>5</Radio>
                      <Radio value={7} name="followUpRadio" title={'0'}>7</Radio>
                      <Radio value={15} name="followUpRadio" title={'0'}>15</Radio>
                      <Radio value={30} name="followUpRadio" title={'0'}>30</Radio>
                      <Radio value={60} name="followUpRadio" title={'0'}>60</Radio>
                      <Radio value={-1} name="followUpRadio" title={'-1'}>Other</Radio>
                    </Radio.Group>
                  </div>
                  <div className="d-md-inline inline-input-other">
                    <div className="form-label-group input-group custom-addon" id="followup_other_block" style={{ display: this.state.followUpRadio === -1 ? "flex" : "none" }}>
                      <input type="text" id="followup_other_value" className="form-control " name="otherVal" maxLength="3"
                        value={this.state.otherVal} onChange={this.handleOnChange} onBlur={() => this.onbooknow()} disabled={!this.state.allowEdit} />
                      <span className="input-group-addon custom-size-addon bg-white">
                        <select className="custom-select" value={this.state.follow_up_Others} select={this.state.follow_up_Others}
                          name="follow_up_Others" onChange={e => this.handleOnChange(e, () => this.onbooknow())} disabled={!this.state.allowEdit}>
                          <option value={'days'}>Days</option>
                          <option value={'weeks'}>Weeks</option>
                          <option value={'months'}>Months</option>
                          <option value={'years'}>Years</option>
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-xl-auto pr-5" style={{ display: this.state.admissionChecked ? "none" : '' }}>
                  <button type="button" className="float-right btn btn-outline-secondary text-uppercase small px-2"
                    disabled={!this.state.allowEdit ? true : this.state.bookNowAllow}
                    onClick={() => this.onbooknow(true)} >BOOK NOW</button>
                </div> */}
              </div>
              :
              null
          }

        </>

        var child_subjective = ss_configuration.find(o => o?.id === 3).sub_items;
        child_variable.custom_field_1 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom1 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom1.name }) }}
                      className={(this.state.speech.identifier === custom1.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom1} name={custom1.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom1} name={custom1.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom1?.custom?.value}
                      className={`form-control subjective-field`}
                      id={custom1.name}
                      name={custom1.name}
                      style={{paddingRight:'170px'}}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom1}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom1.name}>{custom1?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_2 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom2 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom2.name }) }}
                      className={(this.state.speech.identifier === custom2.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom2} name={custom2.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom2} name={custom2.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom2?.custom?.value}
                      className={`form-control subjective-field`}
                      id={custom2.name}
                      style={{paddingRight:'170px'}}
                      name={custom2.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom2}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom2.name}>{custom2?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_3 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom3 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom3.name }) }}
                      className={(this.state.speech.identifier === custom3.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom3} name={custom3.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom3} name={custom3.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom3?.custom?.value}
                      className={`form-control subjective-field`}
                      id={custom3.name}
                      name={custom3.name}
                      style={{paddingRight:'170px'}}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom3}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom3.name}>{custom3?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_4 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom4 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom4.name }) }}
                      className={(this.state.speech.identifier === custom4.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom4} name={custom4.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom4} name={custom4.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom4?.custom?.value}
                      className={`form-control subjective-field`}
                      id={custom4.name}
                      name={custom4.name}
                      disabled={!this.state.allowEdit}
                      style={{paddingRight:'170px'}}
                      value={this.state?.subjective_custom?.custom4}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom4.name}>{custom4?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_5 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom5 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom5.name }) }}
                      className={(this.state.speech.identifier === custom5.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom5} name={custom5.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom5} name={custom5.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom5?.custom?.value}
                      className={`form-control subjective-field`}
                      id={custom5.name}
                      name={custom5.name}
                      disabled={!this.state.allowEdit}
                      style={{paddingRight:'170px'}}
                      value={this.state?.subjective_custom?.custom5}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom5.name}>{custom5?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }

        </>
        child_variable.clinical_notes = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <ClinicalNotes isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} />
              :
              null
          }
        </>
        child_variable.internal_notes = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <InternalNotes isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} />
              :
              null
          }
        </>

        parent_variable.vital_and_kf = <>
          {
            ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 1 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 2 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <>
                {/* {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?  */}
                <VitalsKeyFindings ref={this.vitalAndKfComponentRef} sourcescreen="singlescreen" consult_type={consult_type} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} />
                {/* : null} */}
              </>
              :
              null
          }
        </>;
        parent_variable.patient_hx = <>
          {
            ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 3 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card pathistory-card mb-0">
                  <div className="card-header pathistory-card-head p-0" id="headingTwo">
                    <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapsePatientHistory" aria-expanded="false" aria-controls="collapsePatientHistory" onClick={(e) => this.handleTabClick('objective')}>
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>PATIENT HISTORY</h6>
                        </div>
                        {field_status_patient_history?.map((item, i) =>
                          <>
                            <div className='col-2'>
                              {item.status ?
                                <span className='checkmark'></span>
                                : ""}
                              <span className='pl-2 pathis-headings'>{item.name}</span>
                            </div>
                          </>
                        )}
                      </div>
                    </button>
                  </div>
                  <div id="collapsePatientHistory" className="collapse" aria-labelledby="headingTwo" >
                    {this.state.isObjectiveShow ?
                      <ObjectiveComponentSS sourcescreen="singlescreen" consult_type={consult_type} patient={patient} dataLoading={this.state.dataLoading} initialSetup={this.initialSetup} getPatientData={this.getPatientData} /> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.subjective = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row py-2'>
                <div className='col-xl-12 col-lg-12'>
                  <div className="accordion soap_accordion" id="accordionExample">
                    <div className="card card-bg-lightblue sub-card mb-0 ">
                      <div className="card-body p-0">
                        <div className='container-fluid '>
                          {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                            <>
                              {
                                ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                  ?
                                  <ChiefComplaintsHPI sourcescreen="singlescreen" consult_type={consult_type} history={this.props.history} isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} loadCopyPrescriptionAddedData={this.loadCopyPrescriptionAddedData} default_text={this.state.default_text}/>
                                  :
                                  null
                              }
                              {
                                child_subjective && child_subjective.length > 0
                                  ?
                                  (
                                    child_subjective.map((ssc, i) => {
                                      return (child_variable[ssc.name])
                                    })
                                  )
                                  :
                                  null
                              }
                            </>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              :
              null
          }
        </>;
        parent_variable.diagnosis = <>
          {
            ss_configuration.some(o => (o?.id === 4 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion my-2" id="accordionExample">
                <>
                  {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                    <AssessmentComponentSS
                      ref={this.assessmentComponentRef}
                      history={this.props.history}
                      hospital_id={this.state.hospital_id}
                      patient={patient}
                      allowEdit={allowEdit}
                      cpDiagnosisType={this.state.cpDiagnosisType}
                      allCP={this.state.allCP}
                      otherCP={this.state.otherCP}
                      suggestedCP={this.state.suggestedCP}
                      suggestedDiagnosis={this.state.suggestedDiagnosis}
                      loadCareProtocolAddedData={this.loadCareProtocolAddedData}
                      assessmentLength={this.calculateAssessmentLength.bind(this)}
                    />
                    : null}
                </>
              </div>
              :
              null
          }
        </>;
        parent_variable.medicine = <>
          {
            ss_configuration.some(o => (o?.id === 5 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card medicines-card mb-0" id="med_scroll">
                  <div className="card-header med-card-head p-0" id="medAccordion">
                    <button ref={this.medicineCollapseRef} className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseMedicines" aria-expanded="false" aria-controls="collapseMedicines" onClick={(e) => this.handleTabClick('plan_medicine')}>
                      {/* <h6>MEDICINES</h6> */}
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>MEDICINES</h6>
                        </div>
                        <div className='col-10 text-right pr-5'>
                          {/* <a 
                          href="#" 
                          className='text-secondary pr-3' 
                          onClick={(e) => {
                            this.state.allowEdit && this.medicineComponentRef.current.handleCurrentMedicines();
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        > */}

                          <span href="#" class="pr-3 align-self-center">
                            <CurrentMedsPopup patient={this.state.patient} />
                          </span>
                          <a
                            class="btn btn-outline-secondary small mr-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openMedicineComponent();
                              this.state.allowEdit && this.medicineComponentRef.current.handleOrderSetPopup(e, 'show');
                              e.stopPropagation();
                            }}
                          >
                            ORDER SET
                          </a>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openMedicineComponent();
                              this.state.allowEdit && this.medicineComponentRef.current.handleModalPopup(e, !this.state.isPracticing ? "isPracticingModal" : "showMedicineModal");
                              e.stopPropagation();
                            }}
                          >
                            + MEDICINES
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div id="collapseMedicines" className="collapse" aria-labelledby="medAccordion" >
                    {this.state.isMedShow ?
                      <PlanMedicineComponentSS getDrugsList={this.addedDrugs} ref={this.medicineComponentRef} patient={patient} updateInstructionFromParent={this.updateInstructionFromParent} viewMedicineData={this.viewMedicineData} viewlengthMedicineData={this.viewlengthMedicineData.bind(this)} printCompleteButtonDiabled={this.printCompleteButtonDiabled} /> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.test = <>
          {
            ss_configuration.some(o => (o?.id === 6 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card tests-card mb-0" id="med_scroll">
                  <div className="card-header tests-card-head p-0" id="testAccordion">
                    <button ref={this.testCollapseRef} className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseTests" aria-expanded="false" aria-controls="collapseTests" onClick={(e) => this.handleTabClick('plan_test')}>
                      {/* <h6>TESTS</h6> */}
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>TESTS</h6>
                        </div>
                        <div className='col-10 text-right pr-5'>

                          <a
                            class="btn btn-outline-secondary small mr-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openTestComponent();
                              this.state.allowEdit && this.testComponentRef.current.handleOrderSetPopup('show');
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            ORDER SET
                          </a>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openTestComponent();
                              this.state.allowEdit && this.testComponentRef.current.handleModalPopup(e, !this.state.isPracticing ? "isPracticingModal" : "showTestModal");
                              e.stopPropagation();
                            }}
                          >
                            + TESTS/PROCEDURES
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div id="collapseTests" className="collapse" aria-labelledby="testAccordion" >
                    {this.state.isTestShow ?
                      <PlanTestComponentSS ref={this.testComponentRef} patient={patient} updateInstructionFromParent={this.updateInstructionFromParent} viewTestData={this.viewTestData} viewlengthTestData={this.viewlengthTestData.bind(this)} /> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.instruction = <>
          {
            ss_configuration.some(o => (o?.id === 7 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card instructions-card mb-0" id="med_scroll">
                  <div className="card-header instructions-card-head p-0" id="instr_accordion">
                    <button ref={this.instructionCollapseRef} className={this.state.instructionList?.length > 0 ? "btn btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" : "btn btn-block text-left collapsed float-right mb-0 custom-card-pink instr_padd"} type="button" data-toggle="collapse" data-target="#collapseInstructions" aria-expanded="false" aria-controls="collapseInstructions">
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6 className='mb-0'>INSTRUCTIONS</h6>
                        </div>
                        <div className={this.state.instructionList?.length > 0 ? 'col-10 text-right pr-5' : 'col-10 text-right pr-5 '}>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={e => this.handleModalPopup(e, 'showInstructionModal')}
                          >
                            + INSTRUCTIONS
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  {
                    this.state.instructionList?.length > 0
                      ?
                      <div id="collapseInstructions" className="collapse" aria-labelledby="instr_accordion" >
                        <div className="card-body instructions_body p-0">
                          <div className='container-fluid instruc_container pt-1'>
                            <div class="all_instructions">
                              <Tooltip placement="topLeft" title="Delete All">
                                <i className='icon_delete icon_delete_all float-right cursor-pointer' onClick={() => this.deleteAllInstruction()} ></i>
                              </Tooltip>
                              <div class="row instructions_rows mt-0">
                                {
                                  this.state.instructionList.map((inst, i) => {
                                    return (
                                      <div class=" instructions_list2 col-md-6 d-flex align-items-top pl-2">
                                        <div class="dot-bullet"></div>
                                        <div class="instructions_text p-0">
                                          <p class="p-0">{inst.instruction}</p>
                                        </div>
                                        <div class="col text-center">
                                          <a class="btn nostyle-link p-0 ml-1 mr-2 "
                                            onClick={(e) => this.onInstructionSelect(inst.id, inst.instruction, inst.instruction)}
                                            href="javascript:void(0)"><i class="icon_delete "></i></a>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }
        </>;

        parent_variable.supporting_material = <>
          {
            ss_configuration.some(o => (o?.id === 8 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <>
                {this.state.isSupportingMaterialShow ?
                  <SupportingMaterialSS
                    formType='all'
                    referenceId={this.state?.reference_id}
                    referenceType={HOMECARE_REFERENCE_TYPE}
                    hospitalId={this.state.hospital_id}
                    setMaterialData={(data) => this.getMaterialData(data)}
                    allSupportingMaterial={this.state.materialList}
                    updateSupportingMaterial={this.updateSupportingMaterial}
                  />
                  : null}
              </>
              :
              null
          }
        </>
        parent_variable.referral = <>
          {
            ss_configuration.some(o => (o?.id === 9 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <ReferralComponentSS patient={patient} allowEdit={this.state.allowEdit} />
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_1 = <>
          {
            ss_configuration.some(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom1 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom1.name }) }}
                      className={(this.state.speech.identifier === parent_custom1.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom1} name={parent_custom1.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom1} name={parent_custom1.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom1?.custom?.value}
                      className={`form-control subjective-field`}
                      id={parent_custom1.name}
                      style={{paddingRight:'170px'}}
                      name={parent_custom1.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom1}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom1?.name}>{parent_custom1?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_2 = <>
          {
            ss_configuration.some(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom2 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom2.name }) }}
                      className={(this.state.speech.identifier === parent_custom2?.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom2} name={parent_custom2.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom2} name={parent_custom2.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom2?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom2?.name}
                      name={parent_custom2?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom2}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom2?.name}>{parent_custom2?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_3 = <>
          {
            ss_configuration.some(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom3 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom3.name }) }}
                      className={(this.state.speech.identifier === parent_custom3?.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom3} name={parent_custom3.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom3} name={parent_custom3.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>    
                    <textarea
                      placeholder={parent_custom3?.custom.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      name={parent_custom3?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom3}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom3?.name}>{parent_custom3?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_4 = <>
          {
            ss_configuration.some(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom4 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom4.name }) }}
                      className={(this.state.speech.identifier === parent_custom4.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom4} name={parent_custom4.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom4} name={parent_custom4.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom4?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom4?.name}
                      name={parent_custom4?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom4}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom4?.name}>{parent_custom4?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_5 = <>
          {
            ss_configuration.some(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom5 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom5.name }) }}
                      className={(this.state.speech.identifier === parent_custom5.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom5} name={parent_custom5.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom5} name={parent_custom5.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>    
                    <textarea
                      placeholder={parent_custom5?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom5.name}
                      name={parent_custom5.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom5}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom5?.name}>{parent_custom5?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.other = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 18 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 19 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 20 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="row emrfrm py-2" id="followup">
                <div className="col-12">
                  <div className="card followup_card rounded">
                    <div className="card-body px-3 py-1">
                      {
                        child_others && child_others.length > 0
                          ?
                          (
                            child_others.map((ssc, i) => {
                              return (child_variable[ssc.name])
                            })
                          )
                          :
                          null
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              null

          }

        </>;
      }

    }


    return (
      <>
        {!dataLoading ? (
          <div className="container-fluid emrfrm ">
            <div className="row single_page">
              <div className="col-12">
                {
                  ss_configuration && ss_configuration.length > 0
                ?
                  (
                    this.props.appointment_status == 2
                  ?
                    <h6 className='mb-0 text-center'>Homecare appointment is completed.</h6>
                  :
                    (
                      !this.state.isPracticing
                    ?
                      <h6 className='mb-0 text-center'>Please start practice for consult</h6>
                    :
                      (
                        ss_configuration.map((ss, i) => {
                          return (parent_variable[ss.name])
                        })
                      )
                    )
                  )
                :
                  null
                }
                {
                  this.state.is_recall_doctor == 1 ?
                    <div className='row mt-1'>
                      <div className='col-xl-12 col-lg-12'>
                        <div className={this.state.reviewTxt != '' && this.state.reviewTxt != null ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap `}>
                          <Link
                            onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'review_notes' }) }}
                            className={(this.state.speech.identifier === 'review_notes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                          <textarea class="form-control subjective-field show-placeholder"
                            id="review_notes"
                            name="review_remark"
                            rows="1"
                            onFocus={this.handleOnFocus}
                            onInput={(e) => this.autoHeight(e)}
                            onChange={this.reviewNote}
                            defaultValue={this.state.reviewTxt}
                            value={this.state.reviewTxt}
                          >
                          </textarea>
                          <label for="review_notes">Review Remarks</label>
                        </div>
                      </div>
                    </div> : null
                }
                {this.state.notesModelActive ?
                  <NotesSaveModel content={this.state.content} resetNotesModel={this.resetNotesModel} resetModel={this.resetModel} saveTextBoxData={this.saveTextBoxData} input_type={this.state.input_type} model_type={this.state.model_type} /> : null}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="col d-flex justify-content-center align-self-center mt-2">
            {LOADER_RED}
          </div>
        )}

        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className='row mb-3'>
            <div className='col-12 text-right emrfrm'>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input 
                type="checkbox" 
                className="custom-control-input" 
                id="select_all"
                name="select_all"
                value={this.state.downloadAllfiles} 
                checked={this.state.downloadAllfiles} 
                onChange={(e) => this.selectAllFiles(e)} 
                />
                <label className="custom-control-label" htmlFor="select_all">Select All</label>
              </div>
              <button href={this.state.documentURL} title={this.state.documentURL} disabled={this.state.download_file_loading ? true : false} className={this.state.download_file_loading ? "btn btn-primary px-2" : "btn btn-outline-primary px-2"} onClick={(e) => this.downloadFiles(e)} download>DOWNLOAD {this.state.download_file_loading ? LOADER : null}</button>
            </div>
          </div>
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} beforeChange={this.beforeChange} >
                  {
                    patient_docments?.map((file, index) => (
                      file.type === 'img' ?
                        <figure key={index}><Image className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                        :
                        <iframe key={index} className="mr-3" src={file.src + "#toolbar=0&scrollbar=0&pagemode=none"} ></iframe>

                    ))
                  }
                </Carousel>
              ) : null
            }
          </div>
        </Modal>

      {/* View Admision Request Modals */}
        <Modal
          destroyOnClose={true}
          title={"Active Admission Request"}
          visible={this.state.viewAdmissionGrid}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({viewAdmissionGrid:false})}
          className="emrfrm surgery_modal"
        > 
          <div className="row my-2">
            <div className="col-12 table-responsive">
              <table className="table table-bordered rounded-0 surgerytable">
                <thead>
                  <tr>
                    <th>Request Number</th>
                    <th>Requested By</th>
                    <th>Advised Date</th>
                    <th>Case Type</th>
                    <th>Practitioner Name</th>
                    <th>Speciality</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                { this.admissionListLoad() }
                </tbody>
              </table>
            </div>
          </div> 
        </Modal>

        <Modal
          title={false}
          closable={true}
          visible={this.state.isReferralModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'isReferralModal')}
        >
          <div className="row">
            <div className="col-12 mb-3 text-left"><h6>REFERRAL DETAILS</h6></div>
            {referralDetailsArr?.map((element, index) => (
              (index != 0) ?
                <div className="col-12 mb-3" id="patient_notes_docs">
                  <div className="card bg-grey rounded-0">
                    <div className="card-body">
                      <div className='row'>
                        <div className="col-12">
                          <div className="">
                            <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral {index + 1}</h6>
                            <p className='mb-1'>Referral Date & Time : <span className="text-dark">{element.referral_date_time}</span></p>
                            <p className='mb-1'>Referral By : <span className="text-dark">Dr {element.referral_by}</span></p>
                            <p className='mb-1'>Referral Note : <span className="text-dark">{element.referral_note}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            ))}
            <div className="col-12 text-center mt-3">
              <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={e => this.handleModalPopup(e, 'isReferralModal')}>CANCEL</button>
            </div>
          </div>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal
          title={false}
          closable={false}
          visible={this.state.isPracticingModal}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
          </div>
        </Modal>

        {/* For Modal Instruction  */}
        {this.state.showInstructionModal ?
          <Modal
            title={this.state.actionText + " Instructions"}
            visible={this.state.showInstructionModal}
            onCancel={(e) => this.handleModalPopup(e, 'showInstructionModal')}
            footer={false}
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showInstructionModal ? <InstructionsForm actionText={this.state.actionText} updateData={this.updateInstructionsData} showdeletebutton={this.state.showdeletebutton} addedInstructionsData={this.state.instructionList} handleModalPopup={this.handleModalPopup} /> : null}

          </Modal>
          : null
        }
        <Modal
          visible={this.state.deleteInstructionModal}
          onCancel={this.handleTestModalPopup}
          style={{ top: 40 }}
          title="Are you sure you want to delete all instructions?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={this.handleTestModalPopup}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={this.handleDeletePopSubmit}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        {/* Admit Order Modal */}

        <Modal
          destroyOnClose={true}
          title={"Admit Orders (Internal Use Only)"}
          visible={this.state.admitOrderModal}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({admitOrderModal:false})}
          className="emrfrm"
        >
          {this.state.admitOrderModal ? <AdmitOrders patient={patient} allowEdit={this.state.allowEdit} admissionAdvisedDisabled={this.state.admissionAdvisedDisabled} handleAdmitOrderModal={this.handleAdmitOrderModal}/> : null } 
        </Modal>

      {/* Surgery Request Modal */}

        <Modal
          destroyOnClose={true}
          title={"Surgery Request"}
          visible={this.state.surgeryRequestModal}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({surgeryRequestModal:false})}
          className="emrfrm surgery_modal"
        >
          {this.state.surgeryRequestModal ? <SurgeryForm patient={patient} handleSurgeryCancel={this.handleSurgeryCancel} constants={this.state.constants} allowEdit={this.state.allowEdit} admissionAdvisedDisabled={this.state.admissionAdvisedDisabled} /> : null }
        </Modal>
      </>
    )
  }
}

export default SingleScreenCommon;